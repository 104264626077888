import React, { useRef, useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import Ms from '../../../img/mm.png'
import Fs from '../../../img/fmm.png'

function BodyPoints(props) {
    const Session = props.Session;
    const canvas = useRef(null);
    const mimg = new Image();
    mimg.src = Ms;   
    const fimg = new Image();
    fimg.src = Fs;
    const [EditDivision, setEditDivision] = useState({ w: window.innerWidth - 20, h: window.innerHeight });
    const [ScaleFactor, setScaleFactor] = useState(1);
    const [EditSize, setEditSize] = useState(1);
    const CanvasSize = 900;
    const [EditMode, setEdit] = useState(false);
    const [Undo, setUndo] = useState([]);
    const [Redo, setRedo] = useState([]);    
    const [IsMale, setIsMale] = useState(Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData.SwNw44.IsMale);    
    window.onresize = () => {
        if(EditMode)
        {
            setEdit(false);
            document.body.style.overflowY = "scroll";
        }      
       Resize();    
    }
    const Resize = () => {    
        setEditDivision({ w: window.innerWidth - 20, h: window.innerHeight });
        setEditSize(window.innerHeight - 20 > window.innerWidth ? (window.innerWidth - 20) : window.innerHeight - 100);
        setScaleFactor(CanvasSize / EditSize);
    }
    const Redraw = (SID) => {
        const context = canvas.current.getContext('2d', { willReadFrequently: true });
        
        context.clearRect(0, 0, EditMode ? EditSize : props.width, EditMode ? EditSize : props.height);
        context.drawImage(Session.state.SessionObject.Sessions[SID].SessionData.SwNw44.IsMale ? mimg : fimg, 0, 0, EditMode ? EditSize : props.width, EditMode ? EditSize : props.height);
        for (let index = 0; index < Session.state.SessionObject.Sessions[SID].SessionData.SwNw44.Points.length; index++) {
            let element = Session.state.SessionObject.Sessions[SID].SessionData.SwNw44.Points[index];
            if (!EditMode) {
                element = { x: element.x / (CanvasSize / props.width), y: element.y / (CanvasSize / props.height) }
                drawPoint(element);
            }
            else {
                element = { x: element.x / ScaleFactor, y: element.y / ScaleFactor }
                drawPoint(element);
            }
        }
    }
    const onDown = (event) => {
        const coordinates = getCoordinates(event)
        if (coordinates && Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData.SwNw44.Points) {
            const OriginalCoordinates = { x: coordinates.x * ScaleFactor, y: coordinates.y * ScaleFactor };            
            Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData.SwNw44.Points.push(OriginalCoordinates);
            Save();
            Undo.push(JSON.parse(JSON.stringify(Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData.SwNw44)));
            setUndo(Undo);
            drawPoint(coordinates);
        }
    }
    const Save = () => {
        Session.setState({ "SessionObject": Session.state.SessionObject });
        Session.StorageSave();
    }
    const drawPoint = (coordinates) => {
        const context = canvas.current.getContext('2d', { willReadFrequently: true });
        context.strokeStyle = '#018718'
        context.fillStyle = '#03a41f'
        context.beginPath(); //Start path
        context.arc(coordinates.x, coordinates.y, 4, 0, Math.PI * 2, true);
        context.fill();
        context.closePath();
        context.beginPath();
        context.strokeStyle = '#01df27'
        context.arc(coordinates.x, coordinates.y, 2, 0, Math.PI * 2, true);
        context.stroke();
        context.closePath();
        context.beginPath();
        context.strokeStyle = '#00ff2b'
        context.arc(coordinates.x, coordinates.y, 1, 0, Math.PI * 2, true);
        context.stroke();
    }

    const getCoordinates = (event) => {
        if (!canvas.current) {
            return null
        }
        const CanvasCoord = canvas.current.getBoundingClientRect();
        const x = event.pageX || event.touches[0].pageX
        const y = event.pageY || event.touches[0].pageY
        return {
            x: x - CanvasCoord.x - window.scrollX,
            y: y - CanvasCoord.y - window.scrollY
        }
    }

    const Clear = () => {
        Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData.SwNw44.Points = [];
        Redraw(Session.state.SessionObject.ID);
        Save();
        //Clear();

    }
    const Cancel = () => {
        Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData.SwNw44 = JSON.parse(JSON.stringify(Undo[0]));
        Save();
        setRedo([]);
        setUndo([]);
        Redraw(Session.state.SessionObject.ID);        
    }   
    useEffect(() => {
        Resize();
        Undo[0] = JSON.parse(JSON.stringify(Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData.SwNw44));
        setUndo(Undo);
        if(mimg.complete && mimg.naturalWidth !== 0)
        {
            setTimeout(() => {
                Redraw(Session.state.SessionObject.ID);
            }, 200);
        }
        else{
            mimg.onload =()=>{
                Redraw(Session.state.SessionObject.ID);    
            }
        }
    }, [EditMode])
    const UndoDraw = () => {
        if (Undo.length > 1) {
            Redo.push(Undo.pop());
            Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData.SwNw44 = JSON.parse(JSON.stringify(Undo[Undo.length - 1]));
            setUndo(Undo);
            setRedo(Redo);
            Save();
        }
        Redraw(Session.state.SessionObject.ID);
    }

    const RedoDraw = () => {
        if (Redo.length > 0) {
            Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData.SwNw44 = JSON.parse(JSON.stringify(Redo[Redo.length - 1]));
            Undo.push(Redo.pop());
            setRedo(Redo);
            setUndo(Undo);
            Save();
        }
        Redraw(Session.state.SessionObject.ID);

    }
    const View = (SID) => {
        // if (SID === Session.state.SessionObject.ID) {
        //   setEdit(true);
        //   document.body.style.overflowY = "hidden";
        // }
        // else {
        //   setEdit(false);
        //   document.body.style.overflowY = "scroll";
        // }
        Redraw(SID)
      }
    return (
        <div className='' style={EditMode ? { backgroundColor: "white", color: "black", position: "fixed", top: "0", left: "0", touchAction: 'none', overflow: 'hidden', zIndex: "5500", width: EditDivision.w, height: EditDivision.h } : props.viewOnly ? { backgroundColor: "white", color: "black", textAlign: "center" } : { backgroundColor: "white", color: "black" }}>

            <div className=' d-flex align-items-lg-center justify-content-lg-between ' >
                {EditMode ? <div className='d-flex justify-content-end' >
                    <button type="button" disabled={Undo.length < 2} className="btn btn-outline-info btn-sm m-1" onClick={UndoDraw}   >↶</button>
                    <button type="button" disabled={Redo.length === 0} className="btn btn-outline-info btn-sm m-1" onClick={RedoDraw}   >↷</button>
                    <div className='ms-auto'><button type="button" disabled={Undo.length < 2} className="btn btn-outline-info btn-sm m-1 " onClick={Cancel}   >Cancel</button>
                        <button type="button" className="btn btn-outline-info btn-sm m-1 " onClick={Clear}   >Clear</button>
                        <button type="button" className="btn btn-outline-info btn-sm m-1" onClick={() => { setEdit(false); document.body.style.overflowY = "scroll"; }}   >End</button>
                    </div>                    
                    <div className="btn-group m-1" role="group" aria-label="Basic example">
                            <button type="button" className={!IsMale ? "btn btn-outline-primary btn-sm" : "btn btn-primary btn-sm"} onClick={() => { Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData.SwNw44.IsMale = true; Session.setState({ "SessionObject": Session.state.SessionObject }); setIsMale(true); Redraw(Session.state.SessionObject.ID); }} >♂</button>
                            <button type="button" className={IsMale ? "btn btn-outline-primary btn-sm" : "btn btn-primary btn-sm"} onClick={() => { Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData.SwNw44.IsMale = false; Session.setState({ "SessionObject": Session.state.SessionObject }); setIsMale(false); Redraw(Session.state.SessionObject.ID); }} >♀</button>
                        </div>
                    </div> :
                    <div className='d-flex justify-content-end' >
                        <button type="button" className="btn btn-outline-info btn-sm m-1" onClick={() => { setEdit(true);Resize(); document.body.style.overflowY = "hidden"; }}   >Start</button>
                    </div>
                }


            </div>
            <canvas
                ref={canvas}
                className="border"
                onMouseDown={!EditMode ? undefined : onDown}
                onTouchStart={!EditMode ? undefined : onDown}
                width={!EditMode ? props.width : EditSize}
                height={!EditMode ? props.height : EditSize}
                style={EditMode ? { position: 'absolute', left: (EditDivision.w / 2) - (EditSize / 2) } : { color: "black" }}
            />
            {!EditMode && Session.state.ShowOld && Session.state.SessionObject.Sessions ?
                <div className='d-flex flex-wrap align-items-lg-center justify-content-lg-center'>
                    {Object.keys(Session.state.SessionObject.Sessions).map((s, k) =>
                     (Session.state.SessionObject.Sessions[s].SessionData.SwNw44.Points.length > 0 || s === Session.state.SessionObject.ID) &&<button key={k} type="button" className="btn btn-outline-info btn-sm m-1 " data-ix={s} onClick={(e) => { View(e.target.getAttribute("data-ix")); }}>{s === Session.state.SessionObject.ID ? "⇢" : ""}  {new Date(Session.state.SessionObject.Sessions[s].Date).toDateString()}</button>
                     )}
                </div>
                : ""}
        </div>
    )
}

BodyPoints.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    viewOnly: PropTypes.bool,
    data: PropTypes.object,
    onDraw: PropTypes.func,
    colors: PropTypes.arrayOf(PropTypes.string),
    strokeWidth: PropTypes.number
}

BodyPoints.defaultProps = {
    width: 300,
    height: 300,
    viewOnly: false,
    data: undefined,
    onDraw: undefined,
    colors: ['#7030A2', '#000000', '#0170C1', '#FE0002', '#FFFF01', '#00AF52'],
    strokeWidth: 3
}

export default BodyPoints