import { React, useState } from 'react'
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
export function SessionSession(props) {
    const Session = props.Session;
    const Target = props.Target;
    const [dropdownOpen1, set1Open] = useState(false);
    const [dropdownOpen2, set2Open] = useState(false);
    const [dropdownOpen3, set3Open] = useState(false);

    const Droptxt = "Select";
    const [SelectedOperation1, setSelectedOperation1] = useState(Droptxt);
    const [SelectedOperation2, setSelectedOperation2] = useState(Droptxt);
    const [SelectedOperation3, setSelectedOperation3] = useState(Droptxt);
    const Select = (e) => {
        if (!Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData[Target][e.target.innerText]) { Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData[Target][e.target.innerText] = {} }; 
        setSelectedOperation1(e.target.innerText);
        Session.setState({ "SessionObject": Session.state.SessionObject });
        Session.StorageSave();
    }
    const Select2 = (e) => {
        if (!Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData[Target][SelectedOperation1][e.target.innerText]) { Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData[Target][SelectedOperation1][e.target.innerText] = "" };
         setSelectedOperation2(e.target.innerText); 
         Session.setState({ "SessionObject": Session.state.SessionObject });
         Session.StorageSave();
         

    }
    const Select3 = (e) => {
        if (SelectedOperation1 !== Droptxt && SelectedOperation2 !== Droptxt) {
            Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData[Target][SelectedOperation1][SelectedOperation2] = e.target.innerText;
            setSelectedOperation3(Droptxt);
            setSelectedOperation2(Droptxt);
            setSelectedOperation1(Droptxt);
             Session.setState({ "SessionObject": Session.state.SessionObject}); }            
             Session.StorageSave();    
         

    }
    return <div>        
        {!Session.state.ShowOld && Object.keys(Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData[Target]).map((k)=>
        <div key={k}>
            <div className="d-flex align-items-center justify-content-start">
            <label  className='p-1'>{k}</label> 
            {Object.keys(Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData[Target][k]).map(k2=><label key={k2} className='p-1'><label >{k2}</label>⇢<label>{Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData[Target][k][k2]}</label></label>)}
            <button type='button' className='m-1 btn btn-outline-danger btn-sm ms-auto' onClick={() => {
               delete Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData[Target][k];               
                Session.setState({ "SessionObject": Session.state.SessionObject });
                Session.StorageSave();                
            }
            } >X</button>
            </div>
           
            </div>)}
        <div className="d-lg-flex  justify-content-lg-right ">
            <div className=" m-2" >
                <ButtonDropdown direction="right" toggle={() => { set1Open(!dropdownOpen1) }}
                    isOpen={dropdownOpen1}>
                    <DropdownToggle className="bg-light text-dark" caret>
                        {SelectedOperation1}
                    </DropdownToggle>
                    <DropdownMenu  style={{ height: "200px", overflowY: "scroll" }}>
                        <DropdownItem onClick={Select}>Soft tissue</DropdownItem>
                        <DropdownItem onClick={Select}>HVT with consent</DropdownItem>
                        <DropdownItem onClick={Select}>Articulation</DropdownItem>
                        <DropdownItem onClick={Select}>Mobilization</DropdownItem>
                        <DropdownItem onClick={Select}>Manipulation</DropdownItem>
                        <DropdownItem onClick={Select}>Fascia</DropdownItem>
                        <DropdownItem onClick={Select}>Neural mobilization</DropdownItem>
                        <DropdownItem onClick={Select}>Visceral</DropdownItem>
                        <DropdownItem onClick={Select}>Cranial</DropdownItem>
                        <DropdownItem onClick={Select}>GOT</DropdownItem>
                        <DropdownItem onClick={Select}>BLT</DropdownItem>
                        <DropdownItem onClick={Select}>Ligamentous articular strain</DropdownItem>
                        <DropdownItem onClick={Select}>Functional technique</DropdownItem>
                        <DropdownItem onClick={Select}>MET</DropdownItem>
                        <DropdownItem onClick={Select}>Still technique</DropdownItem>
                        <DropdownItem onClick={Select}>Craniosacral release</DropdownItem>
                        <DropdownItem onClick={Select}>Myofascial release</DropdownItem>
                        <DropdownItem onClick={Select}>Counter strain</DropdownItem>
                        <DropdownItem onClick={Select}>Trigger point treatment</DropdownItem>
                        <DropdownItem onClick={Select}>Jones technique</DropdownItem>
                        <DropdownItem onClick={Select}>Chapman's reflexes</DropdownItem>
                        <DropdownItem onClick={Select}>Tape</DropdownItem>
                        <DropdownItem onClick={Select}>Electro therapy</DropdownItem>
                        <DropdownItem onClick={Select}>Hydro therapy</DropdownItem>
                        <DropdownItem onClick={Select}>Laser therapy</DropdownItem>
                        <DropdownItem onClick={Select}>Shock waves</DropdownItem>
                        <DropdownItem onClick={Select}>Lymphatic drainage</DropdownItem>
                        <DropdownItem onClick={Select}>Stretching</DropdownItem>
                        <DropdownItem onClick={Select}>Strengthening</DropdownItem>
                    </DropdownMenu>
                </ButtonDropdown>
            </div>
            <div className="m-2" >
                <ButtonDropdown  direction="right" toggle={() => { set2Open(!dropdownOpen2) }}
                    isOpen={dropdownOpen2} disabled={SelectedOperation1 ===Droptxt}>
                    <DropdownToggle className="bg-light text-dark" caret>
                        {SelectedOperation2}
                    </DropdownToggle>
                    <DropdownMenu  style={{ height: "200px", overflowY: "scroll" }}>
                    <DropdownItem onClick={Select2}>Cervical</DropdownItem>
                    <DropdownItem onClick={Select2}>C0</DropdownItem>
                    <DropdownItem onClick={Select2}>C1</DropdownItem>
                    <DropdownItem onClick={Select2}>C2</DropdownItem>
                    <DropdownItem onClick={Select2}>C3</DropdownItem>
                    <DropdownItem onClick={Select2}>C4</DropdownItem>
                    <DropdownItem onClick={Select2}>C5</DropdownItem>
                    <DropdownItem onClick={Select2}>C6</DropdownItem>
                    <DropdownItem onClick={Select2}>C7</DropdownItem>
                    <DropdownItem onClick={Select2}>C/T</DropdownItem>
                    <DropdownItem onClick={Select2}>Dorsal</DropdownItem>
                    <DropdownItem onClick={Select2}>T1</DropdownItem>
                    <DropdownItem onClick={Select2}>T2</DropdownItem>
                    <DropdownItem onClick={Select2}>T3</DropdownItem>
                    <DropdownItem onClick={Select2}>T4</DropdownItem>
                    <DropdownItem onClick={Select2}>T5</DropdownItem>
                    <DropdownItem onClick={Select2}>T6</DropdownItem>
                    <DropdownItem onClick={Select2}>T7</DropdownItem>
                    <DropdownItem onClick={Select2}>T8</DropdownItem>
                    <DropdownItem onClick={Select2}>T9</DropdownItem>
                    <DropdownItem onClick={Select2}>T10</DropdownItem>
                    <DropdownItem onClick={Select2}>T11</DropdownItem>
                    <DropdownItem onClick={Select2}>T12</DropdownItem>
                    <DropdownItem onClick={Select2}>Ribs</DropdownItem>
                    <DropdownItem onClick={Select2}>T1</DropdownItem>
                    <DropdownItem onClick={Select2}>T2</DropdownItem>
                    <DropdownItem onClick={Select2}>T3</DropdownItem>
                    <DropdownItem onClick={Select2}>T4</DropdownItem>
                    <DropdownItem onClick={Select2}>T5</DropdownItem>
                    <DropdownItem onClick={Select2}>T6</DropdownItem>
                    <DropdownItem onClick={Select2}>T7</DropdownItem>
                    <DropdownItem onClick={Select2}>T8</DropdownItem>
                    <DropdownItem onClick={Select2}>T9</DropdownItem>
                    <DropdownItem onClick={Select2}>T10</DropdownItem>
                    <DropdownItem onClick={Select2}>T11</DropdownItem>
                    <DropdownItem onClick={Select2}>T12</DropdownItem>
                    <DropdownItem onClick={Select2}>Lumbar</DropdownItem>
                    <DropdownItem onClick={Select2}>L1</DropdownItem>
                    <DropdownItem onClick={Select2}>L2</DropdownItem>
                    <DropdownItem onClick={Select2}>L3</DropdownItem>
                    <DropdownItem onClick={Select2}>L4</DropdownItem>
                    <DropdownItem onClick={Select2}>L5</DropdownItem>
                    <DropdownItem onClick={Select2}>Sacral</DropdownItem>
                    <DropdownItem onClick={Select2}>S1</DropdownItem>
                    <DropdownItem onClick={Select2}>S2</DropdownItem>
                    <DropdownItem onClick={Select2}>S3</DropdownItem>
                    <DropdownItem onClick={Select2}>S4</DropdownItem>
                    <DropdownItem onClick={Select2}>S5</DropdownItem>
                    <DropdownItem onClick={Select2}>Abdomen</DropdownItem>
                    <DropdownItem onClick={Select2}>TMJ</DropdownItem>
                    <DropdownItem onClick={Select2}>SCJ</DropdownItem>
                    <DropdownItem onClick={Select2}>ACJ</DropdownItem>
                    <DropdownItem onClick={Select2}>GHJ</DropdownItem>
                    <DropdownItem onClick={Select2}>Elbow</DropdownItem>
                    <DropdownItem onClick={Select2}>Wrist</DropdownItem>
                    <DropdownItem onClick={Select2}>SIJ</DropdownItem>
                    <DropdownItem onClick={Select2}>Hip</DropdownItem>
                    <DropdownItem onClick={Select2}>Knee</DropdownItem>
                    <DropdownItem onClick={Select2}>Ankle</DropdownItem>
                    <DropdownItem onClick={Select2}>Foot</DropdownItem>
                    </DropdownMenu>
                </ButtonDropdown>
            </div>
            <div className=" m-2" >
                <ButtonDropdown  direction="right" toggle={() => { set3Open(!dropdownOpen3) }}
                    isOpen={dropdownOpen3} disabled={SelectedOperation2 ===Droptxt}>
                    <DropdownToggle className="bg-light text-dark" caret>
                        {SelectedOperation3}
                    </DropdownToggle>
                    <DropdownMenu >
                        <DropdownItem onClick={Select3}>L</DropdownItem>
                        <DropdownItem onClick={Select3}>R</DropdownItem>
                        <DropdownItem onClick={Select3}>B</DropdownItem>                        
                    </DropdownMenu>
                </ButtonDropdown>
            </div>
        </div>     
       
    </div>

}
export default SessionSession