import { useState } from "react";
function UploadCom(props) {
    const Session = props.Session;
    const Target = props.Target;
    return <>
        <form action='AltoRare.com' method='post' encType='multipart/form-data'>
            <input type='hidden' name='key' value={"Patients/" + props.Role + "/" + props.Session.state.SID+props.ix} />
            <div>

            </div>
            <input className="form-control form-control-sm" id="formFileSm" type="file" name="file" />
        </form>
        <label>Notes</label>
        <textarea className="form-control" onChange={(e) => {
            props.Files[props.ix].V = e.target.value;
            props.SetFiles(props.Files);
            Session.state.SWNWFieldState[props.Target][props.ix].V =  e.target.value;            
            Session.setState({ "SWNWFieldState": this.Session.state.SWNWFieldState });
        }}></textarea>
        <button type="button" className="btn btn-outline-info m-2" onClick={(e) => {
                        
            if(e.target.previousSibling.value === "")
            {
                alert("Please insert a Note! cant upload without Notes");
            }
        }}  >Upload</button>
    </>
}

export function TaggedUploader(props) {
    const Session = props.Session;
    const [Files, SetFiles] = useState([]);
    return <div>
        {Files.map((f, ix) => <UploadCom Session={Session} Role={props.STarget} Target={props.Target} Files={Files} SetFiles={SetFiles} ix={ix}  />)}
       <div className="row"> <button type="button" className="btn btn-outline-success" onClick={(e) => {
            Files.push({ V: "", F: Session.state.SID + Files.length,E:false });
            SetFiles(Files);
            Session.state.SWNWFieldState[props.Target] = Files;
            Session.setState({"SWNWFieldState":Session.state.SWNWFieldState});
        }}  >+</button></div>
    </div>
}
export default TaggedUploader;