import React, { Component } from 'react';
import { Collapse,  Navbar, NavbarBrand, NavbarText, NavbarToggler, NavItem } from 'reactstrap';


import './NavMenu.css';
import logo from '../../img/Logo.png'
import { Link, Route, Routes } from 'react-router-dom';
import Login from '../Account/Login';

export class NavMenu extends Component {
  static displayName = NavMenu.name;
  constructor (props) {
    super(props);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };    
    
    this.App = props.App;
  }
  
  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }
  render () {
    return (
      <>
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>          
            <NavbarBrand ><img className='logo' src={logo} alt="swnw logo" /><label><h4 style={{marginLeft: "15px"}}> Hello : {this.App.User.N}</h4></label></NavbarBrand>
            <NavbarText></NavbarText>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">              
                {this.props.nav.map((n,k)=>
                <NavItem key={k}  className={window.location.pathname.includes(n.title.trim())?"border border-dark":""}>
                  <Link className="nav-link" data-ix={k} to={'/' +n.title.trim()} onClick={this.toggleNavbar}>{n.title}</Link>
                  
                </NavItem> )}
                <NavItem>                
                <Link className="nav-link" to="/Login" onClick={this.App.logOut}>Log Out</Link>
                  
                </NavItem>               
              </ul>
            </Collapse>          
        </Navbar>
      </header>
      <Routes>
        <Route path='/' element={this.props.nav[0].component}></Route>
      {this.props.nav.map((n,k)=>
      <Route key={k} path={'/'+n.title.trim()} element={n.component}></Route>
               )}
                {this.props.nav.map((n,k)=>
                  <Route key={k} path={'/' + n.title.trim() + '/*'} element={n.component}></Route>
               )}
              
        <Route path='/Login' element={<Login App={this.App}></Login>}></Route>
      </Routes>
      </>      
    );
  }
}
