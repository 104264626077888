import React, { Component } from 'react';
import Cntr from '../../Country/Countries.json'
import Nat from '../../Country/Nationality.json'
import Rhand from '../../img/R.png'
import LHand from '../../img/L.png'
class Contacts extends Component {
    count = 0;

    constructor(props) {
        super(props);
        this.state = { Numbers: {} };
        this.Parent = props.Parent;

        this.handleContacts = props.handleContacts;
        for (let index = 0; index < props.C.length; index++) {
            const element = props.C[index];
            this.state.Numbers["C" + index] = element;
            this.count++;
        }

    }
    handleChange = e => {
        this.state.Numbers[e.target.name] = e.target.value;
        this.setState({ "Numbers": this.state.Numbers });
    }

    update = () => {
        const arr = [];
        for (const key in this.state.Numbers) {
            if (Object.hasOwnProperty.call(this.state.Numbers, key)) {
                const element = this.state.Numbers[key];
                if (element !== "") {
                    const re = /^[[0-9]+$/;
                    if (!re.test(element)) {
                        this.Parent.App.addSysMessage("Mobile " + element + " ignored: only Numbers", "darkblue", "white");

                    }
                    else {
                        arr.push(element);
                    }

                }


            }
        }

        this.handleContacts(arr);
    }
    render() {
        return <div className='d-flex align-items-lg-center justify-content-lg-between'>
            <div className=''>
                <div>{Object.keys(this.state.Numbers).map((a, k) => <div key={k} className='d-flex'>
                    <input type="text" className="form-control" key={a} data-ix={k} name={"C" + k} value={this.state.Numbers[a]} onChange={this.handleChange} onBlur={this.update} />


                    {k !== 0 ? <button className='btn btn-outline-danger btn-sm m-1 ' data-ix={"C" + k} onClick={(e) => {
                        delete this.state.Numbers[e.target.getAttribute("data-ix")]
                        this.setState({ "Numbers": this.state.Numbers });
                        this.update();
                        setTimeout(() => {
                            window.location.reload();
                        }, 200);
                    }
                    }>-</button> : ""}
                </div>
                )}
                </div>
            </div>

            <div className=''> <button type='button' className='btn btn-outline-success ' onClick={() => { this.state.Numbers["C" + this.count] = ""; this.setState({ "Numbers": this.state.Numbers }); this.count++; }}>+</button></div>
        </div>

    }
}
export class PatientInfo extends Component {
    constructor(props) {
        super(props);
        this.App = props.App;
        this.Parent = props.Parent;
        this.state = props.Parent.state.SessionObject.Patient;
        this.state.DOB = new Date(this.state.DOB);
        this.state.Date = new Date(this.state.Date);
        this.state.Age = ((new Date() - new Date(this.state.DOB)) / 1000 / 60 / 60 / 24 / 365.25).toFixed(2);
        if (!this.state.Referer) { this.state.Referer = { F: "", L: "" }; }
        if (!this.state.Country) { this.state.Country = "62"; }
        if (this.state.Hand === undefined) { this.state.Hand = true; }
        this.state.Changed = false;
    }
    handleChange = e => {
        this.state[e.target.name] = e.target.value;
        this.setState({ [e.target.name]: e.target.value });
        this.Parent.state.SessionObject.Patient[e.target.name] = e.target.value;
        this.Parent.setState({ "SessionObject": this.Parent.state.SessionObject });
        this.CheckChange();
    }
    handleReferer = e => {
        this.state.Referer[e.target.name] = e.target.value;
        this.setState({ "Referer": this.state.Referer });
        this.Parent.state.SessionObject.Patient.Referer[e.target.name] = e.target.value;
        this.Parent.setState({ "SessionObject": this.Parent.state.SessionObject });
        this.CheckChange();
    }
    handleContacts = arr => {
        this.state.Contact = arr;
        this.setState({ "Contact": this.state.Contact });
        this.Parent.state.SessionObject.Patient.Contact = arr;
        this.Parent.setState({ "SessionObject": this.Parent.state.SessionObject });
        this.Parent.StorageSave();
        this.CheckChange();
    }
    CheckChange = () => {
        if (!this.Parent.state.SessionObject.Sessions) {
            this.setState({ "Changed": true });
        }
    }
    StartSession = () => {
        const DataToSend = { PID: this.Parent.state.SessionObject.PID }
        this.App.SWNWPost(DataToSend, "kxuirzpflgnf5ax4x45isz2qcu0zdnlq", (data) => {
            this.TriggerSession(data);
        }, (Data) => {

            this.App.addSysMessage(Data.message, "gold", "darkred");
        });
    }
    TriggerSession = (data) => {
        this.Parent.state.SessionObject.Patient.Date = new Date(data.body.Sessions[data.body.ID].Date);
        data.body.Patient.Date = new Date(data.body.Sessions[data.body.ID].Date);
        if (data.body.Sessions) {

            if (!data.body.Sessions[data.body.ID].SessionData["SwNw61"]) {
                data.body.Sessions[data.body.ID].SessionData["SwNw61"] = "";
            }
            this.Parent.state.FirstSessionDate = new Date();
            for (const SessionId in data.body.Sessions) {
                if (Object.hasOwnProperty.call(data.body.Sessions, SessionId)) {
                    if (new Date(this.Parent.state.FirstSessionDate) > new Date(data.body.Sessions[SessionId].Date)) {
                        this.Parent.state.FirstSessionDate = data.body.Sessions[SessionId].Date
                    }


                }
            }
            this.Parent.setState({ "FirstSessionDate": this.Parent.state.FirstSessionDate });

        }

        localStorage.setItem("SessionObject", JSON.stringify(data.body));
        this.Parent.setState({ "SessionObject": data.body });
    }
    EditSession = (e) => {
        const SID = e.target.getAttribute("data-ix")
        if (SID !== undefined && SID !== null) {
            const DataToSend = { ID: SID, PID: this.Parent.state.SessionObject.PID }
            this.App.SWNWPost(DataToSend, "kxuirzpflgnf5ax4x45isz2qcu0zdnlq", (data) => {





                this.TriggerSession(data);
            }, (Data) => {
                this.App.addSysMessage(Data.message, "gold", "darkred");
            });
        }
    }
    Save = () => {
        const DataToSend = { ID: this.state.ID, First: this.state.First, Last: this.state.Last, Date: this.state.Date, DOB: this.state.DOB, Email: this.state.Email, Address: this.state.Address, Contact: this.state.Contact, Discount: this.state.Discount, Referer: this.state.Referer, Country: this.state.Country, Hand: this.state.Hand }
        this.App.SWNWPost(DataToSend, "lfmxh7fsjzdgtvn5fokl4onms40bqhja", (data) => {
            this.Parent.setState({ "SessionObject": undefined });
            localStorage.removeItem("SessionObject");
        }, (Data) => {
            this.App.addSysMessage(Data.message, "gold", "darkred");
        });
    }
    componentDidMount() {

    }

    render() {
        return <div className=''>

            <div className='row'>
                <div className='col-lg-8'>
                    <div className='d-lg-flex align-items-lg-center justify-content-lg-between'>
                        <div className="form-group m-1"><label htmlFor="Date">{this.Parent.state.SessionObject.Sessions ? "Today's Date" : "1st visit Date"}</label><input type="date" className="form-control " id="Date" value={this.state.Date.toISOString().substring(0, 10)} name="Date" onChange={(e) => {
                            if (e.target.valueAsDate === null || e.target.valueAsDate === undefined) {
                                return;
                            }
                            this.state.Date = e.target.valueAsDate; this.setState({ "Date": this.state.Date });
                            this.Parent.state.SessionObject.Patient.Date = e.target.valueAsDate;
                            if (this.Parent.state.SessionObject.Sessions)
                                this.Parent.state.SessionObject.Sessions[this.Parent.state.SessionObject.ID].Date = e.target.valueAsDate;
                            this.Parent.setState({ "SessionObject": this.Parent.state.SessionObject });
                            this.CheckChange();

                        }} /></div>
                        <div className="form-group m-1"><label htmlFor="First">First</label><input type="text" className="form-control" name="First" value={this.state.First} onChange={this.handleChange} /></div>
                        <div className="form-group m-1"><label htmlFor="Last">Last</label><input type="text" className="form-control" name="Last" value={this.state.Last} onChange={this.handleChange} /></div>
                        <div className="form-group m-1"><label htmlFor="Discount">Discount %</label><input type="number" className="form-control" name="Discount" max={100} value={this.state.Discount} onChange={(e) => { this.handleChange(e); }} /></div>
                    </div>

                    <div className='d-lg-flex align-items-lg-center justify-content-lg-between'>
                        <div className="form-group m-1"><label htmlFor="dob"> DOB  </label><input type="date" className="form-control" id="dob" value={this.state.DOB.toISOString().substring(0, 10)} name="DOB" onChange={(e) => {
                            if (e.target.valueAsDate === null || e.target.valueAsDate === undefined) {
                                return;
                            }
                            this.state.DOB = e.target.valueAsDate;
                            this.state.Age = ((new Date() - new Date(this.state.DOB)) / 1000 / 60 / 60 / 24 / 365.25).toFixed(2); //new Date().getFullYear() - new Date(e.target.valueAsDate).getFullYear();                            
                            this.setState({ "DOB": this.state.DOB });
                            this.Parent.state.SessionObject.Patient.DOB = this.state.DOB;
                            this.Parent.setState({ "SessionObject": this.Parent.state.SessionObject });
                            this.CheckChange();
                        }} /></div>
                        <div className="form-group m-1"><label htmlFor="age"> Age </label><input type="number" className="form-control" id="age" name="Age" value={this.state.Age} onChange={(e) => {
                            this.state.Age = e.target.value;
                            this.state.DOB = new Date();//new Date(new Date().getFullYear() - e.target.value, document.getElementById('dob').valueAsDate.getMonth(), document.getElementById('dob').valueAsDate.getDate());
                            this.state.DOB.setDate((e.target.value * 365.25 * -1));
                            this.setState({ "DOB": this.state.DOB });
                            this.Parent.state.SessionObject.Patient.DOB = this.state.DOB;
                            this.Parent.setState({ "SessionObject": this.Parent.state.SessionObject });
                            this.CheckChange();
                        }} /></div>
                        {/* {!this.Parent.state.SessionObject.Sessions ?  :
                            <div className="form-control m-1 pl-3 text-start" style={{width:"208px"}}>
                                <label htmlFor="Contact" >Age</label>
                                <div className=''>
                                    {new Date().getFullYear() - this.state.DOB.getFullYear()}
                                </div>
                            </div>
                        } */}

                        <div className="form-group m-1"><label htmlFor="Email">Email</label><input type="email" className="form-control" name="Email" value={this.state.Email} onChange={this.handleChange} /></div>
                        <div className="form-group m-1"><label htmlFor="Address">Address</label><input type="text" className="form-control" name="Address" value={this.state.Address} onChange={this.handleChange} /></div>
                    </div>
                    <div className='d-lg-flex align-items-lg-center justify-content-lg-between'>
                        <div className="form-group m-1 ">
                            <label htmlFor="RefFirst">Referer First</label>
                            <input type="text" className="form-control" name="F" value={this.state.Referer.F} onChange={this.handleReferer} />
                        </div>
                        <div className="form-group m-1">
                            <label htmlFor="RefLast">Referer Last</label>
                            <input type="text" className="form-control" name="L" value={this.state.Referer.L} onChange={this.handleReferer} />
                        </div>
                        <div className="form-group m-1"><label htmlFor="Country">Nationality</label><img width="15px" style={{ margin: "5px" }} src={"https://altorare-common.s3.us-west-2.amazonaws.com/Images/Flags/" + Cntr[this.state.Country][9].split(',')[0]} />
                            <select name='Country' className="form-control" value={this.state.Country} onChange={this.handleChange}>

                                {Object.keys(Cntr).map((a, k) => <option value={a} key={a} >{Nat[Cntr[a][2]]} </option>)}
                            </select>
                        </div>
                        {/* > */}
                        <div className="form-group m-1"><label >Hand</label>
                            <div className='row'>
                                <div className="btn-group btn-group-sm" role="group" style={{ transform: "scale(0.85)" }} >
                                    <div className='btn-group btn-group' >
                                        <input type="radio" className="btn-check" name="LHand" id="LHand" autoComplete="off" checked={this.state.Hand === false} onChange={(e) => {
                                            this.state.Hand = false;
                                            this.setState({ "Hand": false });
                                            this.Parent.state.SessionObject.Patient.Hand = false;
                                            this.Parent.setState({ "SessionObject": this.Parent.state.SessionObject });
                                            this.CheckChange();
                                        }} />
                                        <label className="btn btn-outline-primary" htmlFor="LHand">
                                            <img width="30px" src={LHand} />
                                        </label>
                                    </div>
                                    <div className='btn-group btn-group' >
                                        <input type="radio" className="btn-check" name="RHand" id="RHand" autoComplete="off" checked={this.state.Hand === true} onChange={(e) => {
                                            this.state.Hand = true;
                                            this.setState({ "Hand": true });
                                            this.Parent.state.SessionObject.Patient.Hand = true;
                                            this.Parent.setState({ "SessionObject": this.Parent.state.SessionObject });
                                            this.CheckChange();
                                        }} />
                                        <label className="btn btn-outline-primary" htmlFor="RHand">
                                            <img width="30px" src={Rhand} />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="form-group col-lg-3 m-3 border rounded ">
                    <label htmlFor="Contact">Contact Numbers</label>
                    <div className='row '>
                        <Contacts C={this.state.Contact} handleContacts={this.handleContacts} Parent={this.Parent} />
                    </div>
                </div>
            </div>
            {!this.Parent.state.SessionObject.Sessions ?
                <>
                    <button type="button" className="btn btn-outline-info m-2" onClick={this.Parent.Cancel}>Cancel</button>
                    <button type="button" className="btn btn-outline-info m-2" onClick={this.Save}>Just Save Profile</button>
                    {this.Parent.state.SessionObject.SessionsDates && !this.state.Changed ?
                        <>

                            {this.Parent.state.SessionObject.SessionsDates.map((a, k) =>
                                <button type="button" key={k} className="btn btn-outline-dark m-2" data-ix={a.ID} onClick={this.EditSession}>{new Date(a.Date).toDateString()}</button>
                            )}

                        </>
                        : ""}
                    {!this.state.Changed && <button type="button" className="btn btn-outline-info m-2" onClick={this.StartSession}>Start New Session</button>}
                </>
                : ""
            }
        </div>
    }
}
export default PatientInfo