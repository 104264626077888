import { Component } from "react";

export class PatientPayments extends Component {
    constructor(props) {
        super(props);
        this.App = props.App;
        this.state = {
            ContactToFind: "",
            Message: "",
            Amount: "",
            Date: new Date(),
            PaymentObject: localStorage.getItem("PaymentObject")?JSON.parse(localStorage.getItem("PaymentObject")):undefined
        }
    }
    FindPatient = () => {
        const re = /^[[0-9]+$/;
        if (!re.test(this.state.ContactToFind)) {
            this.App.addSysMessage("Mobile : only Numbers", "darkblue", "white");              
            return;
        }   
        this.App.SWNWPost({ C: this.state.ContactToFind }, "xqeyvyki3cf6o3e7k4vo2bzcde0ebsrf",
            (data) => {
                localStorage.setItem("PaymentObject",JSON.stringify(data.body));
                this.setState({ "PaymentObject": data.body });                 
                this.setState({ "ContactToFind": "", "Message": "", "Amount": 0, "Date": new Date() });
            },
            (res) => {
                this.App.addSysMessage(res.message, "gold", "black");
                this.setState({ "PaymentObject": undefined, "ContactToFind": "", "Message": "Not Found", "Amount": 0, "Date": new Date() });
            });
    }
    RecordPayment = () => {
        if ( this.state.Amount===0) {
            this.App.addSysMessage("Amount : cannot be 0", "darkblue", "white");              
            return;
        } 
        const re = /^\d+(?:\.\d{1,2})?$/;
        if (!re.test(this.state.Amount)) {
            this.App.addSysMessage("Amount : With 1/2 decimals only->"+this.state.Amount.toFixed(2), "darkblue", "white");              
            return;
        }   
        this.App.SWNWPost({ A: this.state.Amount, D: this.state.Date, PID: this.state.PaymentObject.Patient.ID }, "5vzdaovbl74vkemo5wnumt6ema0faobb",
            (data) => {
                this.state.PaymentObject.Payments[data.body.ID] = data.body;
                this.setState({ "PaymentObject": this.state.PaymentObject, "ContactToFind": "", "Message": "", "Amount": 0, "Date": new Date() });
            },
            (res) => {
                this.App.addSysMessage(res.message, "gold", "black");
            });
    }
    Total = () => {
        let Totl = 0;
        for (const key in this.state.PaymentObject.Payments) {

            Totl += parseFloat(this.state.PaymentObject.Payments[key].Amount);


        }
        return Totl.toFixed(2);
    }
    componentDidMount(){
        const arr = window.location.pathname.split('/');
        const URLParam = arr.pop();
        if(URLParam!== "Payments")
        {
            this.App.SWNWPost({ C: URLParam }, "xqeyvyki3cf6o3e7k4vo2bzcde0ebsrf",
            (data) => {
                this.setState({ "PaymentObject": data.body });                 
                this.setState({ "ContactToFind": "", "Message": "", "Amount": 0, "Date": new Date() });
            },
            (res) => {
                this.App.addSysMessage(res.message, "gold", "black");
                this.setState({ "PaymentObject": undefined, "ContactToFind": "", "Message": "Not Found", "Amount": 0, "Date": new Date() });
            });        
        }
    }

    render() {
        return <div className="container">
            <h1>Payments</h1>
            {!this.state.PaymentObject ?
                <div className="card text-center m-2">
                    <div className="card-header">
                        Find Patient
                    </div>
                    <div className="card-body">
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="nptContact" key="1" onChange={(e) => { this.state.ContactToFind = e.target.value; this.setState({ "ContactToFind": this.state.ContactToFind }) }} value={this.state.ContactToFind} onKeyDown={(e) => { if (e.key === "Enter") { this.FindPatient(); } }} />
                            <label htmlFor='nptContact'>Contact Number</label>
                        </div>
                    </div>
                    {this.state.Message}
                    <div className="card-footer text-muted">
                        <button type="button" className="btn btn-outline-info" onClick={this.FindPatient}>Find</button>
                    </div>
                </div>
                :
                <div className="card text-center m-2">
                    <div className="card-header">
                      <h1>  {this.state.PaymentObject.Patient.First} {this.state.PaymentObject.Patient.Last}</h1>
                    </div>
                    <div className="card-body">
                    <h3>Contact</h3>
                        <div className="m-2">{this.state.PaymentObject.Patient.Contact.map((a, k) => <label className="m-2" key={k} htmlFor="C">{a}</label>)}</div>
                        <h3>Payment History</h3>
                        <div className="d-flex flex-wrap align-items-lg-center justify-content-lg-center">
                        {Object.keys(this.state.PaymentObject.Payments).map(a => <div key={a} className="card text-center m-2" >
                            <div className="card-header bg-success text-white">
                                {new Date(this.state.PaymentObject.Payments[a].Date).toDateString()}
                            </div>
                            <div className="card-body">
                                {this.state.PaymentObject.Payments[a].Amount} EGP
                            </div>

                        </div >
                        )}
                          </div>
                        <div className="form-floating mb-3"><input type="date" className="form-control" id="Date" value={this.state.Date.toISOString().substring(0, 10)} name="Date" onChange={(e) => {
                             if (e.target.valueAsDate === null || e.target.valueAsDate === undefined) {
                                return;
                            }
                            this.state.Date = e.target.valueAsDate;
                            this.setState({ "Date": e.target.valueAsDate });
                        }} /><label htmlFor="Date"> Date  </label></div>
                        <div className="form-floating mb-3">
                            <input type="number" step="any" max="999999" className="form-control" id="nptAmount" key="2" onChange={(e) => {
                                if(e.target.value=== ""){
                                    e.preventDefault();
                                     return;
                                     }; this.state.Amount = e.target.value; this.setState({ "Amount":parseFloat(e.target.value) }) }} value={this.state.Amount}  onKeyDown={(e) => { if (e.key === "Enter") { this.RecordPayment(); } }} />
                            <label htmlFor='nptAmount'>Amount</label>
                        </div>
                    </div>
                    <div className="card-footer text-muted">                        
                            <div className="m-1 d-flex justify-content-around badge bg-warning"><label className="align-self-center">Total:</label><label >{this.Total()}</label><label className="align-self-center">EGP</label></div>
                            <button type="button" className="btn btn-outline-info m-1" onClick={this.RecordPayment}>Record Payment</button>
                            <button type="button" className="btn btn-outline-info  m-1" onClick={()=>{localStorage.removeItem("PaymentObject");this.setState({"PaymentObject":undefined})}}>Close</button>
                    </div>
                </div>
            }
        </div>
    }
}
export default PatientPayments


