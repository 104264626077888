import React, { useRef, useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

function PainDistribution(props) {
  const Session = props.Session;
  const canvas = useRef(null);  
  const [EditMode, setEdit] = useState(false);
  const [Undo,setUndo] = useState([]);
  const [Redo,setRedo] = useState([]);  
  const [drawing, setDrawing] = useState(false)
  const [Sposition, setSPosition] = useState(null)
  const [position, setPosition] = useState(null)
  

  const onDown = useCallback((event) => {
    const coordinates = getCoordinates(event)
    if (coordinates) {
      setPosition(coordinates)
      setSPosition(coordinates);
      setDrawing(true);
     
    }     
  }, [])
  const drawPoint = (coordinates) => {
    const context = canvas.current.getContext('2d', { willReadFrequently: true });
    context.strokeStyle = 'darkred'
    context.fillStyle = 'red'
    context.beginPath(); //Start path
    context.arc(coordinates.x, coordinates.y, 5, 0, Math.PI * 2, true);
    context.fill();
    context.closePath();
  }
  const onUp = useCallback((event) => {
    const coordinates = getCoordinates(event)
    if (coordinates && Sposition && coordinates.x === Sposition.x && coordinates.y === Sposition.y) {
      Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData.SwNw12.Points.push(coordinates);      
      Save();
      Undo.push(JSON.parse(JSON.stringify(Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData.SwNw12)));
      setUndo(Undo);
      drawPoint(coordinates);
    }
    setDrawing(false);
    setPosition(null);
   
    
  }, [drawing, position, Sposition])

  const getCoordinates = (event) => {
    if (!canvas.current) {
      return null
    }

    const CanvasCoord = canvas.current.getBoundingClientRect();  
    const x = event.pageX || event.touches[0].pageX
    const y = event.pageY || event.touches[0].pageY
    return {
        x: x - CanvasCoord.x - window.scrollX,
        y: y - CanvasCoord.y -  window.scrollY
    }
  }
  const UndoDraw =()=>{    
    
    
    if(Undo.length > 1)
    {
      Redo.push(Undo.pop());
      Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData.SwNw12 = JSON.parse(JSON.stringify(Undo[Undo.length - 1]));      
      setUndo(Undo);
    setRedo(Redo);
      Save();
    }      
    ReDraw(Session.state.SessionObject.ID);
  }

  const RedoDraw =()=>{
  
    if(Redo.length>0)
    {
      Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData.SwNw12 = JSON.parse(JSON.stringify(Redo[Redo.length - 1])); 
      Undo.push(Redo.pop());
      setRedo(Redo);
      setUndo(Undo);     
      Save();
    }      
    ReDraw(Session.state.SessionObject.ID);
    
  }
  const ReDraw=(SID)=>{
    const context = canvas.current.getContext('2d', { willReadFrequently: true });
    context.clearRect(0, 0, props.width, props.height);
    context.strokeStyle = 'red';
    context.lineWidth = 1;
    context.beginPath();
    context.moveTo(40, 10);
    context.lineTo(40, props.height - 30);
    context.stroke();
    context.closePath();
    context.beginPath();
    context.moveTo(props.width - 30, props.height - 30);
    context.lineTo(40, props.height - 30);
    context.stroke();
    context.closePath();
    context.fillStyle = 'black';
    context.font = "20px Arial";
    context.fillText("Am", 1, 20);
    context.fillText("Pm", (props.width / 2) - 10, props.height - 10);    
    for (let index = 1; index < Session.state.SessionObject.Sessions[SID].SessionData.SwNw12.Lines.length; index++) {
      const element = Session.state.SessionObject.Sessions[SID].SessionData.SwNw12.Lines[index];
      if(element.f)
      {
        drawLine(element.f, element.t);  
      }        
      else{
        drawLine(Session.state.SessionObject.Sessions[SID].SessionData.SwNw12.Lines[index -1], element);
      }
      
    }
    for (let index = 0; index < Session.state.SessionObject.Sessions[SID].SessionData.SwNw12.Points.length; index++) {
      const element = Session.state.SessionObject.Sessions[SID].SessionData.SwNw12.Points[index];
      drawPoint(element);
    }
  }

  const onMove = useCallback(
    (event) => {
      if (drawing) {
        const newPosition = getCoordinates(event)
        if (position && newPosition) {
          drawLine(position, newPosition);          
          Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData.SwNw12.Lines.push({f:position,t: newPosition});   
          Save();
          Undo.push(JSON.parse(JSON.stringify(Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData.SwNw12)));
          setUndo(Undo);    
          setPosition(newPosition)
        }
      }
    },
    [drawing, position]
  )

  const drawLine = (originalPosition, newPosition) => {
    if (!canvas.current) {
      return null
    }
    const context = canvas.current.getContext('2d', { willReadFrequently: true });
    if (context) {
      context.strokeStyle = '#000000';
      context.lineJoin = 'round';
      context.lineWidth = props.strokeWidth;
      context.beginPath();
      context.moveTo(originalPosition.x, originalPosition.y);
      context.lineTo(newPosition.x, newPosition.y);
      context.closePath();
      context.stroke();      
    }
  }
  
  const Clear =()=>{
    Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData.SwNw12.Lines =[];
    Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData.SwNw12.Points =[];
    setRedo([]);
    setUndo([]);
    Save();
    ReDraw(Session.state.SessionObject.ID);
  }
  const Cancel = ()=>{
    Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData.SwNw12 = JSON.parse(JSON.stringify(Undo[0]));
    Save();
    setRedo([]);
    setUndo([]);
    ReDraw(Session.state.SessionObject.ID);
  }
  const Save=()=>{
    Session.setState({"SessionObject":Session.state.SessionObject}) 
    Session.StorageSave();
  }
  useEffect(() => {
    Undo[0] = JSON.parse(JSON.stringify(Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData.SwNw12));
    setUndo(Undo);
    ReDraw(Session.state.SessionObject.ID);
  }, [props.data])
  const View = (SID) => {
    // if (SID === Session.state.SessionObject.ID) {
    //   setEdit(true);
    //   document.body.style.overflowY = "hidden";
    // }
    // else {
    //   setEdit(false);
    //   document.body.style.overflowY = "scroll";
    // }
    ReDraw(SID)
  }
  return (
    <div className='' >
      
      {EditMode ? <div className='d-flex justify-content-end' > <button type="button" disabled={Undo.length < 2} className="btn btn-outline-info btn-sm m-1" onClick={UndoDraw}   >↶</button>
        <button type="button" disabled={Redo.length === 0} className="btn btn-outline-info btn-sm m-1" onClick={RedoDraw}   >↷</button>
        <div className='ms-auto'><button type="button" disabled={Undo.length < 2} className="btn btn-outline-info btn-sm m-1 " onClick={Cancel}   >Cancel</button>
          <button type="button" className="btn btn-outline-info btn-sm m-1 " onClick={Clear}   >Clear</button>
          <button type="button" className="btn btn-outline-info btn-sm m-1" onClick={()=>{setEdit(false);document.body.style.overflowY = "scroll";}}   >End</button>
          </div></div> :
        <div className='d-flex justify-content-end' >
          <button type="button" className="btn btn-outline-info btn-sm m-1" onClick={()=>{setEdit(true);document.body.style.overflowY = "hidden";}}   >Start</button>         
        </div>
      }

       

       {/* <button type="button" className="btn btn-outline-info btn-sm m-1 ms-auto" onClick={Clear}   >✗</button> */}
       
      <canvas
        ref={canvas}
        onMouseDown={!EditMode ? undefined : onDown}
        onTouchStart={!EditMode ? undefined : onDown}
        onMouseUp={!EditMode ? undefined : onUp}
        onTouchEnd={!EditMode ? undefined : onUp}
        onMouseLeave={!EditMode ? undefined : onUp}
        onMouseMove={!EditMode ? undefined : onMove}
        onTouchMove={!EditMode ? undefined : onMove}
        width={props.width}
        height={props.height}     
      />
        {!EditMode && Session.state.ShowOld && Session.state.SessionObject.Sessions ?
          <div className='d-flex flex-wrap align-items-lg-center justify-content-lg-center'>
            {Object.keys(Session.state.SessionObject.Sessions).map((s, k) =>
             (Session.state.SessionObject.Sessions[s].SessionData.SwNw12.Points.length > 0 || s === Session.state.SessionObject.ID) &&<button key={k} type="button" className="btn btn-outline-info btn-sm m-1 " data-ix={s} onClick={(e)=>{View(e.target.getAttribute("data-ix"));}}>{s === Session.state.SessionObject.ID?"⇢":""}  {new Date(Session.state.SessionObject.Sessions[s].Date).toDateString()}</button> 
             )}
          </div>
          : ""}

    </div>
  )
}

PainDistribution.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  viewOnly: PropTypes.bool,
  data: PropTypes.object,
  onDraw: PropTypes.func,
  colors: PropTypes.arrayOf(PropTypes.string),
  strokeWidth: PropTypes.number
}

PainDistribution.defaultProps = {
  width: 400,
  height: 400,
  viewOnly: false,
  data: undefined,
  onDraw: undefined,
  colors: ['#7030A2', '#000000', '#0170C1', '#FE0002', '#FFFF01', '#00AF52'],
  strokeWidth: 3
}

export default PainDistribution