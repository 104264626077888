import React, { Component } from 'react'
import ChangePassword from '../Account/ChangePassword';
import PatientProfile from '../DE/PatientProfile';
import PatientPayments from '../DE/SessionControls/PatientPayments';
import PatientSearch from '../PatientSearch';
import { NavMenu } from './NavMenu';


class DataEntry extends Component {
    App = undefined;
    constructor(props) {
        super(props);
        this.App = props.App;
       this.navigtion= [
        { index: 0, title: "Search", component:<PatientSearch App={this.App} />},        
        { index: 1, title: "Profiles", component:<PatientProfile App={this.App} />},
        { index: 2, title: "Payments", component:<PatientPayments App={this.App} />},
        { index: 3, title: "Password", component:<ChangePassword App={this.App} />}
        ]
    }
    render() {
        return <div>
            <NavMenu App={this.App} nav={this.navigtion} navigate={this.App.navigate}  />
            <div>
                
            </div>
        </div>
    }
}

export default DataEntry;
