import PropTypes from 'prop-types'
import { useState } from 'react';
import PainDistribution from './SessionControls/PainDistribution';
import BodyPoints from './SessionControls/BodyPoints';
import Lab from './SessionControls/Lab';
import SessionSession from './SessionControls/SessionSession';
import TaggedUploader from './SessionControls/TaggedUploader';
import Preg from './SessionControls/Preg';
import PPWDIAGv2 from './SessionControls/PPWDIAGv2';
class PatientField {
    constructor(props) {
        this.GS = props.GS;
        this.IT = props.IT;
        this.N = props.N;
        this.T = props.T;
        this.O = props.O;
        this.TS = props.TS;
        this.SC = props.SC;
        this.F = props.F;
        this.S = props.S;

    }
}
export const SWNWFields = [
    new PatientField({ GS: 2, IT: 2, N: "SwNw01", T: "Family" }),
    new PatientField({ GS: 2, IT: 2, N: "SwNw02", T: "Occupation" }),
    new PatientField({ GS: 2, IT: 1, N: "SwNw03", T: "Job Details" }),
    new PatientField({ GS: 2, IT: 2, N: "SwNw04", T: "Activities" }),
    new PatientField({ GS: 2, IT: 4, N: "SwNw05", T: "Weight" }),
    new PatientField({ GS: 2, IT: 4, N: "SwNw06", T: "Height" }),
    new PatientField({ GS: 2, IT: 4, N: "SwNw07", T: "+C-" }),
    <hr />,
    new PatientField({ GS: 2, IT: 18, N: "SwNw08", T: "Patient present with" }),
    new PatientField({ GS: 2, IT: 1, N: "SwNw09", T: "VAS" }),
    new PatientField({ GS: 2, IT: 2, N: "SwNw10", T: "Worse for" }),
    new PatientField({ GS: 2, IT: 2, N: "SwNw11", T: "Better for" }),
    new PatientField({ GS: 2, IT: 15, N: "SwNw12", T: "" }),//Pain Distribution
    new PatientField({ GS: 2, IT: 2, N: "SwNw13", T: "Onset" }),
    new PatientField({ GS: 2, IT: 1, N: "SwNw14", T: "What did you to since the Onset?" }),
    new PatientField({ GS: 2, IT: 2, N: "SwNw15", T: "Associated Symptoms" }),
    new PatientField({ GS: 2, IT: 2, N: "SwNw16", T: "Other Symptoms" }),
    new PatientField({ GS: 2, IT: 1, N: "SwNw17", T: "Progression" }),
    new PatientField({
        GS: 2, IT: 1, N: "SwNw18", T: "Sleep Pattern", TS: [
            new PatientField({ GS: 2, IT: 8, N: "SwNw57", T: "", O: ["Good", "Bad"], SC: [1], F: "SwNw18" })
        ]
    }),
    new PatientField({
        GS: 2, IT: 1, N: "SwNw19", T: "Night Sweats", TS: [
            new PatientField({ GS: 2, IT: 8, N: "SwNw58", T: "", O: ["No", "Yes"], SC: [1], F: "SwNw19" })
        ]
    }),    
    new PatientField({
        GS: 2, IT: 1, N: "SwNw20", T: "Pain Disturb", TS: [
            new PatientField({ GS: 2, IT: 8, N: "SwNw21", T: "", O: ["No", "Yes"], SC: [1], F: "SwNw20" })
        ]
    }),
    new PatientField({ GS: 2, IT: 2, N: "SwNw22", T: "Medication" }),
    new PatientField({ GS: 2, IT: 2, N: "SwNw23", T: "Operation/Investigation" }),
    new PatientField({ GS: 2, IT: 1, N: "SwNw24", T: "Accidents/Traumas" }),
    new PatientField({ GS: 2, IT: 1, N: "SwNw25", T: "Allergies" }),
    new PatientField({ GS: 2, IT: 1, N: "SwNw26", T: "Food Avoided" }),
    new PatientField({ GS: 2, IT: 1, N: "SwNw27", T: "Appetite" }),
    new PatientField({ GS: 2, IT: 8, N: "SwNw28", T: "Fluid Intake", O: ["No", "Yes"] }),
    new PatientField({ GS: 2, IT: 1, N: "SwNw29", T: "Cardio-Vascular" }),
    new PatientField({
        GS: 2, IT: 1, N: "SwNw30", T: "Respiratory:", TS: ["Smoker?",
            new PatientField({ GS: 2, IT: 8, N: "SwNw31", T: "Smoker", O: ["No", "Yes"] })]
    }),
    new PatientField({ GS: 2, IT: 1, N: "SwNw32", T: "Dermatology" }),
    new PatientField({ GS: 2, IT: 1, N: "SwNw33", T: "Neurological:FFB?" }),
    new PatientField({ GS: 2, IT: 1, N: "SwNw34", T: "GIT" }),
    new PatientField({
        GS: 2, IT: 1, N: "SwNw35", T: "Bowel Habbits", TS: [
            new PatientField({ GS: 2, IT: 8, N: "SwNw59", T: "", O: [ "Regular","Irregular"], SC: [1], F: "SwNw35" })
        ]
    }),    
    new PatientField({ GS: 2, IT: 1, N: "SwNw36", T: "G-U tract:" }),
    new PatientField({ GS: 0, IT: 17, N: "SwNw37", T: "Lab Requests" }),
    new PatientField({
        GS: 2, IT: 1, N: "SwNw38", T: "Obs/Gynae", TS: [
            "Pill?",
            new PatientField({ GS: 2, IT: 8, N: "SwNw39", T: "", O: ["No", "Yes"] }),
            "Period?"]
    }),
    new PatientField({ GS: 2, IT: 1, N: "SwNw40", T: "Endocrine", TS: [new PatientField({ GS: 2, IT: 8, N: "SwNw41", T: "", O: ["N/A","Fatigue", "Cold", "Warm"] })] }),
    new PatientField({ GS: 2, IT: 1, N: "SwNw42", T: "Family History" }),
    new PatientField({ GS: 2, IT: 1, N: "SwNw43", T: "Flags" }),
    new PatientField({ GS: 2, IT: 16, N: "SwNw44", T: "" }),//Body Points
    new PatientField({ GS: 2, IT: 18, N: "SwNw45", T: "Diagnosis" }),
    new PatientField({ GS: 2, IT: 12, N: "SwNw46", T: "Session" }),
    new PatientField({ GS: 2, IT: 2, N: "SwNw47", T: "Advice" }),
    new PatientField({ GS: 2, IT: 2, N: "SwNw48", T: "Exercise" }),
    new PatientField({ GS: 2, IT: 17, N: "SwNw49", T: "Lab Results" }),
    new PatientField({ GS: 2, IT: 14, N: "SwNw50", T: "Scan", O: ["Scan"] }),
    new PatientField({ GS: 2, IT: 14, N: "SwNw51", T: "PDF", O: ["PDF"] }),
    new PatientField({ GS: 2, IT: 1, N: "SwNw52", T: "Diabetes", TS: [new PatientField({ GS: 2, IT: 8, N: "SwNw55", T: "", O: ["No", "Yes"], SC: [1], F: "SwNw52" })] }),
    new PatientField({ GS: 2, IT: 1, N: "SwNw53", T: "Hypertension",TS:[ new PatientField({ GS: 2, IT: 8, N: "SwNw56", T: "", O: ["No", "Yes"], SC: [1], F: "SwNw53" })] }),
    new PatientField({ GS: 2, IT: 19, N: "SwNw54", T: "Pregnancy" ,TS:[ new PatientField({ GS: 2, IT: 8, N: "SwNw60", T: "", O: ["No", "Yes"], SC: [1], F: "SwNw54" })] }),
    new PatientField({ GS: 2, IT: 2, N: "SwNw61", T: "Follow Up" })
    
    
];


PatientField.propTypes = {
    GS: PropTypes.number,
    IT: PropTypes.number,
    N: PropTypes.string,
    T: PropTypes.string,
    O: PropTypes.array,
    TS: PropTypes.array,
    SC: PropTypes.array
}
function Check(IT, Value,SC) {
    if (Value === undefined)
        return false;
    switch (IT) {
        case 0:
            return false;
        case 1:
            return Value === "" ? false : true
        case 2:
            return Value === "" ? false : true;
        case 3:
            return Value === "" ? false : true
        case 4:
            return Value === 0 ? false : true
        case 5:

            break;
        case 6:

            break;
        case 7:

            break;
        case 8:
            return Value === 0 ? false : true

        case 9:

            break;
        case 12://SessionSession
            return Object.keys(Value).length === 0 ? false : true;
        case 14://TaggedUploader
            return Object.keys(Value).length === 0 ? false : true;
        case 15://PainDistribution
            return Value && Value.Points && Value.Lines && Object.keys(Value.Lines).length === 0 && Object.keys(Value.Points).length === 0 ? false : true;
        case 16://BodyPoints
            return Value && Value.Points && Object.keys(Value.Points).length === 0 ? false : true;
        case 17://Lab Results
            return Object.keys(Value).length === 0 ? false : true;
            case 18://Key Value
            return Object.keys(Value).length === 0 ? false : true;
            case 19://Pregnancy
            return Value.PW === 0 ? false : true;
        default:
            return false
    }

}
function SWNWInputView(props) {




    switch (props.Field.IT) {
        case 0:
            return ""
        case 1:
            return <label> {props.Value}</label>
        case 2:
            let arr1 = props.Value.split("\n")
            return <ul className='list-group '>{arr1.map((s, k) => <li className='list-group-item border-0 m-0 p-0' key={k}>{s}</li>)}</ul>
        case 3:
            let arr = props.Value.split("\n")
            return <ol className='list-group list-group-numbered'>{arr.map((s, k) => <li className='list-group-item border-0 m-0 p-0' key={k}>{s}</li>)}</ol>
        case 4:
            return <label>{props.Value}</label>
        case 5:

            break;
        case 6:

            break;
        case 7:

            break;
        case 8:
            return <label className='badge bg-primary m-1'>{props.Field.O[props.Value]}</label>;
        case 9:
            break;
        case 12:
            return <div>{Object.keys(props.Value).map(k => <div key={k}>
                <label className='p-1'>{k}:</label>
                {Object.keys(props.Value[k]).map(k2 => <label key={k2} className='p-1'><label >{k2}</label>⇢<label>{props.Value[k][k2]}</label></label>)}</div>)}</div>;    
        case 14:
            break;
        // return <TaggedUploader Target={props.Field.N} STarget={props.Field.O[0]} Session={props.Session} />

        case 15:
            break;
        // return <PainDistribution width={300} height={200} Session={props.Session} OnSave={() => { props.Session.setState({ "SWNWFieldState": props.Session.state.SWNWFieldState }); props.Session.StorageSave(); }}></PainDistribution>
        case 16:
            break;
        // return <BodyPoints width={320} height={300} Session={props.Session} OnSave={() => { props.Session.setState({ "SWNWFieldState": props.Session.state.SWNWFieldState }); props.Session.StorageSave(); }}  ></BodyPoints>
        case 17:
            break;
        // return <Lab Session={props.Session} Target={props.Field.N} />
        case 18:
            return props.Value? <div>{Object.keys(props.Value).map(k => <div key={k}>
                <label className='p-1'>{k}⇢{props.Value[k].P && props.Value[k].P}</label><label className='p-1'>{props.Value[k].V && props.Value[k].V}</label>

                </div>)}</div>:"";   
                case 19:
                    let Dx =new Date(props.Session.state.SessionObject.Sessions[props.Session.state.SessionObject.ID].Date);
                    return props.Value? <div> <label className='p-1'>Pregnancy number : {props.Value.PN}, Pregnancy Week : 1<sup>st</sup>:{props.Value.PW},                     
                    T: {39 - props.Value.PW - Math.floor((new Date(props.Value.EX) - Dx) / (86400000 * 7))},
                    S: {39 - Math.floor((new Date(props.Value.EX) - Dx) / (86400000 * 7))}</label><label className='p-1'>, EDD : {new Date(props.Value.EX).toLocaleDateString()}</label></div>:"";   
                        
        default:
            break;
    }
}
function SWNWField(props) {
    return <div >
        <div className={props.ShowOld ? "m-1 border border-dark rounded text-start" : "text-start"}>
            {props.Field.GS === undefined ?
                props.Field
                :
                props.Field.GS === 0 ?
                    <div className="card mb-4  rounded-3 shadow-sm">
                        <div className="card-header py-3">
                            <h4 className="my-0 fw-normal">{props.Field.T}

                                <label htmlFor={props.Field.N}>{props.Field.TS ?
                                    props.Field.TS.map((TSs, k) => (typeof TSs === "string") ?
                                        TSs
                                        :
                                        <SWNWInput key={k} Field={TSs} Session={props.Session} />) : ""}</label></h4>
                        </div>
                        <div className="card-body ">

                            <SWNWInput Field={props.Field} Session={props.Session} />

                            {/* View */}
                            {props.ShowOld && Object.keys(props.Session.state.SessionObject.Sessions).map(SID => <div key={SID + props.Field.N}>

                                {!Check(props.Field.IT, props.Session.state.SessionObject.Sessions[SID].SessionData[props.Field.N],props.Field.SC) ? "" :
                                    <><label className='badge bg-dark text-white '>{new Date(props.Session.state.SessionObject.Sessions[SID].Date).toDateString()}</label>
                                        {props.Field.TS && props.Field.TS.map(TSs => typeof TSs === "string" ? "" : !Check(TSs.IT, props.Session.state.SessionObject.Sessions[SID].SessionData[TSs.N],props.Field.SC) ? "" :
                                            <SWNWInputView Value={props.Session.state.SessionObject.Sessions[SID].SessionData[TSs.N]} Field={TSs} Session={props.Session} />
                                        )}
                                        {/* //// */}

                                        <SWNWInputView Value={props.Session.state.SessionObject.Sessions[SID].SessionData[props.Field.N]} Field={props.Field} Session={props.Session} /></>
                                }
                            </div>)}
                            {/*end View */}

                        </div>
                    </div>
                    :
                    props.Field.GS === 1 ?
                        <div className='p-1'>

                            <div className='form-floating'>
                                <SWNWInput Field={props.Field} Session={props.Session} />
                                <label htmlFor={props.Field.N}>{props.Field.T}{props.Field.TS ?
                                    props.Field.TS.map((TSs, k) => (typeof TSs === "string") ?
                                        TSs
                                        :
                                        <SWNWInput key={k} Field={TSs} Session={props.Session} />) : ""}</label>
                            </div>
                            {/* View */}
                            {props.ShowOld && Object.keys(props.Session.state.SessionObject.Sessions).map(SID => <div key={SID + props.Field.N}>
                                {!Check(props.Field.IT, props.Session.state.SessionObject.Sessions[SID].SessionData[props.Field.N],props.Field.SC) ? "" :
                                    <><label className='badge bg-dark text-white '>{new Date(props.Session.state.SessionObject.Sessions[SID].Date).toDateString()}</label>
                                        {props.Field.TS && props.Field.TS.map(TSs => typeof TSs === "string" ? "" : !Check(TSs.IT, props.Session.state.SessionObject.Sessions[SID].SessionData[TSs.N],props.Field.SC) ? "" :
                                            <SWNWInputView Value={props.Session.state.SessionObject.Sessions[SID].SessionData[TSs.N]} Field={TSs} Session={props.Session} />
                                        )}
                                        {/* //// */}
                                        <SWNWInputView Value={props.Session.state.SessionObject.Sessions[SID].SessionData[props.Field.N]} Field={props.Field} Session={props.Session} /></>
                                }
                            </div>)}
                            {/*end View */}
                        </div>
                        :
                        <div className='p-1'>

                            {(props.Field.N !== "SwNw12" && props.Field.N !== "SwNw44") && <label className='' htmlFor={props.Field.N}>
                                <label style={{ opacity: "0.65" }}>{props.Field.T}</label>
                                {props.Field.TS ? props.Field.TS.map((TSs, k) => (typeof TSs === "string") ?
                                    <label key={k} style={{ opacity: "0.65" }} >{TSs}</label>
                                    :
                                    <SWNWInput key={k} Field={TSs} Session={props.Session} />) : ""}</label>}
                            <SWNWInput Field={props.Field} Session={props.Session} />
                            {/* View */}
                            {props.ShowOld && Object.keys(props.Session.state.SessionObject.Sessions).map(SID => <div key={SID + props.Field.N}>
                                {!Check(props.Field.IT, props.Session.state.SessionObject.Sessions[SID].SessionData[props.Field.N],props.Field.SC) ? "" :
                                    props.Field.N === "SwNw12" || props.Field.N === "SwNw44" ?
                                        ""
                                        :
                                        <><label className='badge bg-dark text-white '>{new Date(props.Session.state.SessionObject.Sessions[SID].Date).toDateString()}</label>
                                            {/* //// */}
                                            {props.Field.TS && props.Field.TS.map(TSs => typeof TSs === "string" ? "" : !Check(TSs.IT, props.Session.state.SessionObject.Sessions[SID].SessionData[TSs.N],props.Field.SC) ? "" :
                                                <SWNWInputView Value={props.Session.state.SessionObject.Sessions[SID].SessionData[TSs.N]} Field={TSs} Session={props.Session} />
                                            )}
                                            {/* //// */}
                                            <SWNWInputView Value={props.Session.state.SessionObject.Sessions[SID].SessionData[props.Field.N]} Field={props.Field} Session={props.Session} /></>
                                }
                            </div>)}
                            {/*end View */}
                        </div>
            }
        </div>
    </div>

}
function SWNWInput(props) {
    const [Value, setValue] = useState(props.Session.state.SessionObject.Sessions[props.Session.state.SessionObject.ID].SessionData[props.Field.N]);
    let isHidden = false;
    if (props.Field.TS) {
        for (let index = 0; index < props.Field.TS.length; index++) {
            const element = props.Field.TS[index];
            if (element.SC && element.F === props.Field.N && !element.SC.includes(props.Session.state.SessionObject.Sessions[props.Session.state.SessionObject.ID].SessionData[element.N])) {
                isHidden = true;
            }
        }
    }
    //style={props.Field.N === "SwNw08" && window.innerWidth > 991 ? { height: "206px" } : (props.Field.N === "SwNw04" || props.Field.N === "SwNw01") && window.innerWidth > 991 ? { height: "100px" } : {}}


    switch (props.Field.IT) {
        case 0:
            return ""
        case 1:
            return <input type="text" className="form-control" hidden={isHidden} name={props.Field.N} value={Value} onChange={(e) => { props.Session.HandleText(e); setValue(e.target.value) }} />
        case 2:
            return <textarea className="form-control" name={props.Field.N} value={Value} 
                onChange={(e) => { e.target.style.height = (e.target.scrollHeight + 2) + "px"; props.Session.HandleText(e); setValue(e.target.value) }} />
        case 3:
            return <textarea className="form-control" name={props.Field.N} value={Value} 
                onChange={(e) => { e.target.style.height = (e.target.scrollHeight + 2) + "px"; props.Session.HandleText(e); setValue(e.target.value) }} />
       
        case 4:
            return <input type="number" className="form-control" name={props.Field.N} value={Value} onChange={(e) => { props.Session.HandleNumber(e); setValue(parseInt(e.target.value)); }} />
        case 5:

            break;
        case 6:

            break;
        case 7:

            break;
        case 8:
            return <div className="btn-group btn-group-sm " role="group" style={{ transform: "scale(0.85)" }} >
                {props.Field.O.map((a, k) =>
                    <div className='btn-group btn-group' key={k}>
                        <input type="radio" className="btn-check" name={props.Field.N} id={props.Field.N + k} autoComplete="off" checked={Value === k} data-ix={k} 
                    onChange={(e) => { 
                        props.Session.HandleOptions(e); 
                        setValue(parseInt(e.target.getAttribute("data-ix")));
                         if (props.Field.SC) {
                             if (props.Field.SC.includes(parseInt(e.target.getAttribute("data-ix")))) {
                                 document.getElementsByName(props.Field.F)[0].removeAttribute("hidden"); }
                                  else { document.getElementsByName(props.Field.F)[0].setAttribute("hidden", true); } } }} />
                        <label className="btn btn-outline-primary" htmlFor={props.Field.N + k} key={"l" + k} >{a}
                        </label>
                    </div>
                )}
            </div>
        case 9:
            return <div className="btn-group btn-group-sm " role="group" style={{ transform: "scale(0.85)" }} >
            {props.Field.O.map((a, k) =>
                <div className='btn-group btn-group' key={k}>
                    <input type="radio" className="btn-check" name={props.Field.N} id={props.Field.N + k} autoComplete="off" checked={Value === k} data-ix={k} 
                onChange={(e) => { 
                    props.Session.HandleOptions(e); 
                    setValue(parseInt(e.target.getAttribute("data-ix")));
                     if (props.Field.SC) {
                         if (props.Field.SC.includes(parseInt(e.target.getAttribute("data-ix")))) {
                             document.getElementsByName(props.Field.F)[0].removeAttribute("hidden"); }
                              else { document.getElementsByName(props.Field.F)[0].setAttribute("hidden", true); } } }} />
                    <label className="btn btn-outline-primary" htmlFor={props.Field.N + k} key={"l" + k} >{a}
                    </label>
                </div>
            )}
        </div>
        case 12:
            return <SessionSession Target={props.Field.N} Session={props.Session}  ></SessionSession>
        case 14:
            return <TaggedUploader Target={props.Field.N} STarget={props.Field.O[0]} Session={props.Session} />

        case 15:

            return <PainDistribution width={300} height={200} Session={props.Session} OnSave={() => { props.Session.setState({ "SWNWFieldState": props.Session.state.SWNWFieldState }); props.Session.StorageSave(); }}></PainDistribution>
        case 16:

            return <BodyPoints Session={props.Session} OnSave={() => { props.Session.setState({ "SWNWFieldState": props.Session.state.SWNWFieldState }); props.Session.StorageSave(); }}  ></BodyPoints>
        case 17:
            return <Lab Session={props.Session} Target={props.Field.N} />
            case 18: 
            return <PPWDIAGv2 Session={props.Session} Target={props.Field.N}></PPWDIAGv2>
                
                case 19:
                    return<Preg Session={props.Session} Target={props.Field.N} hidden={isHidden} />
                
        default:
            return <input className='form-control' type="text"></input>
    }

}


// const SessionDataFieldTypes = {
//     0: "",
//     1: "text",
//     2: "textarea",
//     3: "numberdTextarea",
//     4: "number",
//     5: "date",
//     6: "range",
//     7: "Options",
//     8: "SmallOptions",
//     9: "OptionswithComments",
//     10: "DrawPoints",
//     11: "DrawlinePoints",
//     12: "OptionKeyInputValue",
//     13: "File",
//     14: "TaggedFile",
//     15: "paindestribution",
//     16: "bodyPoints",
//     17: "ListKeyInputValue",
//     18: "KeyValue" PPWv2 Diagnosis V2
//     19:  "Pregnancy"


// }
// export const SWNWFieldState = {
//     SwNw01: "",
//     SwNw02: "",
//     SwNw03: "",
//     SwNw04: "",
//     SwNw05: 0,
//     SwNw06: 0,
//     SwNw07: 0,
//     SwNw08: "",
//     SwNw09: "",
//     SwNw10: "",
//     SwNw11: "",
//     SwNw12: { Points: [], Lines: [] },
//     SwNw13: "",
//     SwNw14: "",
//     SwNw15: "",
//     SwNw16: "",
//     SwNw17: "",
//     SwNw18: 0,
//     SwNw19: 0,
//     SwNw20: "",
//     SwNw21: 1,
//     SwNw22: "",
//     SwNw23: "",
//     SwNw24: "",
//     SwNw25: "",
//     SwNw26: "",
//     SwNw27: "",
//     SwNw28: 0,
//     SwNw29: "",
//     SwNw30: "",
//     SwNw31: 0,
//     SwNw32: "",
//     SwNw33: "",
//     SwNw34: "",
//     SwNw35: 0,
//     SwNw36: "",
//     SwNw37: [],
//     SwNw38: "",
//     SwNw39: 0,
//     SwNw40: "",
//     SwNw41: 0,
//     SwNw42: "",
//     SwNw43: "",
//     SwNw44: { IsMale: true, Size: 300, Points: [] },
//     SwNw45: "",//diagnosis
//     SwNw46: {},//Session
//     SwNw47: "",//Advice
//     SwNw48: "",//Exercise
//     SwNw49: [],//Lab
//     SwNw50: [],//scan
//     SwNw51: [],//pfd
//SwNw52: {},SwNw53: {},SwNw54: { PW: 0, EX: new Date() }
// };
export default SWNWField
