import React from 'react';
import { useNavigate } from 'react-router-dom';
import banner from '../../img/swnwbanner.png';
import './Login.css';
export function Login(props) {    
    const [name, setName] = React.useState("");
    const [pswrd, setPswrd] = React.useState("");
    const [message, setMessage] = React.useState("");    
    let navigate = useNavigate();
    const handleSubmit = () => {
      
      if(name.length === 0 || pswrd.length === 0)
      {
          if(name.length === 0 )
          setMessage("Please, insert your username !");      
          if( pswrd.length === 0)
          setMessage("Please, insert your password!");      
          return;
      }      
      props.App.SWNWPost({n:name,p:pswrd},"wdu5tbhsybksilazfbho264iqu0pduhj",
      (resJson) =>{
        props.App.User = resJson.body;
        localStorage.setItem('AppVersion',JSON.stringify(props.App.AppCurrentVersion)); 
        props.App.setState({ "IsAuthin": true });        
        localStorage.setItem('User',JSON.stringify(props.App.User));
        navigate("../", { replace: true });        
      }
      ,()=>{
        setMessage("Access-Denied");
      props.App.setState({ "IsAuthin": false });});
    };   
    return (<div className='LoginView'>     
      <img src={banner} alt="SWNW" style={{ width: "175px" }} />
      <h2 className='text-center' style={{color:"#7CA7AD",textShadow: "1px 1px 1px #b1b1b16e"}}> Data Entry Mode</h2>
      <div className='Login'>
         <strong>Login</strong>             
        <div className="mb-3">
        <label htmlFor="name">User Name</label>
            <input type="text" className="form-control" id="name" key="1"  value={name} onChange={(e) => setName(e.target.value)}/>
            
        </div>
        <div className="b-3">
        <label htmlFor="pswrd">Password</label>
            <input type="password" className="form-control" id="pswrd" key="2"   value={pswrd} onChange={(e) => setPswrd(e.target.value)} onKeyDown={(e)=>{
              if(e.key === "Enter")
              {
                handleSubmit();
              }
            }} />
            
        </div>
        <div className="mb-3"><label>{message}</label></div>
        <div className="mb-3"><button type="button" onClick={handleSubmit} className="btn btn-secondary" >Login</button></div>  
        
        
      </div>     
    </div>);  
}
export default Login;