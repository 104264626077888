import React, { Component } from 'react';
import logo from './img/Logo.png';
import Login from './Components/Account/Login.js'
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import Owner from './Components/Menu/Owner';
import DataEntry from './Components/Menu/DataEntry';

function SWNWMessage(props) {
  return <div className='badge' style={{ backgroundColor: props.bgcolor ? props.bgcolor : "black", color: props.color ? props.color : "white" }}>{props.msg}</div>;
}
class App extends Component {
  constructor(props) {
    super(props);
    this.AppCurrentVersion = new Date(2022,10,31);
    this.AppVersion =localStorage.getItem('AppVersion') ?new Date(JSON.parse(localStorage.getItem('AppVersion'))) : new Date(2022,1,1);;  
    this.User = localStorage.getItem('User') ? JSON.parse(localStorage.getItem('User')) : {};
    if((!this.AppVersion && this.User.K) || (this.AppVersion && this.AppVersion < this.AppCurrentVersion))
    {
      localStorage.clear();
    }

    this.state = {
      SysMessages: [],
      Loading: false,
      IsAuthin: this.User.N ? true : false
    }

    this.busy = false;
    this.PostRequests = [];
  }


  addSysMessage = (msg, bgcolor, color) => {
    this.state.SysMessages.push({ "id": this.state.SysMessages.length, "msg": msg, "bgcolor": bgcolor, "color": color });
    this.setState({ "SysMessages": this.state.SysMessages });
    setTimeout(() => {
      this.state.SysMessages.pop();
      this.setState({ "SysMessages": this.state.SysMessages });
    }, 10000);

  }

  async SWNWPost(PostObject, PostKey, OKCallBack, FailCallBack, Scilent) {
    if (!this.busy) {
      this.busy = true;
      try {
        if (!Scilent){
          this.setState({ "Loading": true });}
        let res = await fetch("https://" + PostKey + ".lambda-url.eu-west-1.on.aws/", {
          method: "POST",
          body: JSON.stringify({ D: this.User.D, K: this.User.K, U: this.User.U, data: PostObject })
        });
        if (res.ok) {
          let data = await res.json();
          if (data.code === 200) {
            this.User.K = data.K;
            this.User.D = data.D;
            this.User.U = data.U;
            localStorage.setItem('User', JSON.stringify(this.User));
            OKCallBack(data);
          }
          else if (data.code !== 401) {
            this.User.K = data.K;
            this.User.D = data.D;
            this.User.U = data.U;
            localStorage.setItem('User', JSON.stringify(this.User));
            FailCallBack(data);
          }
          
          else {
            FailCallBack(data);
            this.logOut();
          }
          this.busy = false;
          this.setState({ "Loading": false });

        }
        else if (res.status === 502) {            
          this.busy = false;
          this.addSysMessage("Server time out call +201159799468", "red", "white");
          this.setState({ "Loading": false });
        }
        else {
          this.busy = false;
          this.addSysMessage("Server Error", "darkred", "white");
          this.setState({ "Loading": false });

        }
        if (this.PostRequests.length !== 0) {
          const newreq = this.PostRequests.pop();
          this.SWNWPost(newreq[0], newreq[1], newreq[2], newreq[3], newreq[4]);
        }
      } catch (err) {
        console.log(err.stack);
        console.log(err.message);
        if(err.message === "Failed to fetch" || err.message.includes("NetworkError"))
        {
          this.addSysMessage("Please check your internet", "darkred", "white");
        }
        else
        {
          this.addSysMessage(err.message, "darkred", "white");
        }
        
        this.busy = false;
        this.setState({ "Loading": false });
        if (this.PostRequests.length !== 0) {
          const newreq = this.PostRequests.pop();
          this.SWNWPost(newreq[0], newreq[1], newreq[2], newreq[3], newreq[4]);
        }
      }
    }
    else {
      this.PostRequests.push([PostObject, PostKey, OKCallBack, FailCallBack, Scilent]);
    }
  }
  ToTimeString(StartingHour, Duration, TimeSlot) {
    let STH = new Date();
    STH.setHours(StartingHour.substring(0, 2));
    STH.setMinutes(StartingHour.substring(3, 5));
    if (TimeSlot !== "1") {
      STH.setMinutes(STH.getMinutes() + ((parseInt(TimeSlot - 1)) * Duration));
    }
    let SAPM = STH.getHours() >= 12 ? " PM" : " AM";
    let SH = STH.getHours() % 12 === 0 ? "12" : STH.getHours() % 12;
    let SM = STH.getMinutes().toString()
    SM = SM.length === 1 ? "0" + SM : SM;

    return SH + ":" + SM + SAPM;

  }
  logOut = async () => {
    if (this.state.IsAuthin) {
      await this.SWNWPost({}, "l2ct7sdpbkzhv7vy2uuwoh4jgq0kacud", () => {
        this.User = {};
        localStorage.clear();

      }, () => {
        this.User = {};
        localStorage.clear();

      });
    }
    this.User = {};
    localStorage.clear();
    this.setState({ "IsAuthin": false });
  }
  render() {
    return (
      <div className="App">
        <header className="App-header" style={this.state.Loading ? { display: "flex", height: '100%' } : { display: "none" }}>
          <img src={logo} className="App-logo" alt="logo" />
        </header>
       
        <div className='content'>

          {this.state.IsAuthin === false || this.User.O === undefined ?
            <Login App={this} />
            : this.User.O === 0 || this.User.O === 1  ?
              <div><Owner App={this} /></div>
              : <DataEntry App={this} />
          }
        </div>
        <div id="pops">{this.state.SysMessages.map((e) => <SWNWMessage key={e.id} msg={e.msg} color={e.color} bgcolor={e.bgcolor} />)}</div>
      </div>
    );
  }



}

export default App;
