import React, { useState } from "react";
import banner from '../../img/swnwbanner.png';
import './Login.css';

export function ChangePassword(props) {
    const App = props.App;
    const [OldPass, setOldPass] = useState("");
    const [NewPassC, setNewPassC] = useState("");
    const [NewPass, setNewPass] = useState("");
    const [message, setMessage] = useState("");  
    const [Color, setColor] = useState("darkred");    
    const handleSubmit = () => {
        setColor("darkred");
        if (OldPass === "") {
            setMessage("Old Password : Please insert, All chars Allowd");
            return;
        }
        if (OldPass.length < 6) {
            setMessage("Old Password : Not less than 6 Chars");
            return;
        }
      
        if (NewPass === "") {
            setMessage("New Password : Please insert, All chars Allowd");
            return;
        }
        if (NewPass.length < 6) {
            setMessage("New Password : Not less than 6 Chars");
            return;
        }
        if (NewPass !== NewPassC) {
            setMessage("New Password & Confirmation : do not match");
            return;
        }
     
        App.SWNWPost({N:App.User.N,OP:OldPass,NP:NewPass},"pl37nzorvhgczyap5iwfs7nhsy0lvqol",
        (resJson) =>{
            setMessage("Password Changed");
            setColor("green");
        }
        ,()=>{
          setMessage("No Changes");
    
    });
    }
    return (<div className='LoginView'>     
    <img src={banner} alt="SWNW" style={{ width: "175px" }} />
    <h2 className='text-center' style={{color:"#7CA7AD",textShadow: "1px 1px 1px #b1b1b16e"}}> Change Password</h2>
    <div className='Login'>
                   
      <div className="m-3">
      <label htmlFor="oldPass">Old Password</label>
          <input type="text" className="form-control" id="oldPass" key="0"  value={OldPass} onChange={(e) => setOldPass(e.target.value)}/>
          
      </div>
     
      <div className="m-3">
      <label htmlFor="newPass">New Password</label>
          <input type="password" className="form-control" id="newPass" key="2"   value={NewPass} onChange={(e) => setNewPass(e.target.value)} onKeyDown={(e)=>{
            if(e.key === "Enter")
            {
              handleSubmit();
            }
          }} />
          
      </div>
      <div className="m-3">
      <label htmlFor="NewPassC">Confirmation</label>
          <input type="password" className="form-control" id="NewPassC" key="1"  value={NewPassC} onChange={(e) => setNewPassC(e.target.value)}/>
          
      </div>
      <div className="mb-3"><label style={{color:Color}}>{message}</label></div>
      <div className="m-3"><button type="button" onClick={handleSubmit} className="btn btn-secondary" >Change</button></div>  
      
      
    </div>     
  </div>);  
}

export default ChangePassword;


// <div>
//         <div className='row text-center'>
//             <h1>Change Password </h1>
//             <div className="col-5">
//                 <div className="form-group m-1"><label htmlFor="OldPass">OldPass</label><input type="text" className="form-control" name="OldPass" value={OldPass} onChange={e => {
//                     setOldPass(e.target.value);
//                 }} /></div>
//             </div>
//         </div>
//     </div>