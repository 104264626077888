export const LabArray = ["17-Hydroxyprogesterone",
"17-OHP",
"17-OH Progesterone",
"24-Hour Urine Protein",
"24 hour Urine 5-hydroxyindoleacetic acid",
"24 hour 5-HIAA",
"25-hydroxyvitamin D",
"3-day Measles",
"3-ANCA",
"3-ANCA",
"4-marker Screen",
"4q12 (CHIC2) deletion",
"5-HIAA",
"5-hydroxyindoleacetic Acid",
"5-Hydroxytryptamine",
"5-HT",
"72 Hour Fecal Fat",
"A-G Ratio",
"A1AT",
"A1c",
"AAT",
"ABGs",
"ACA",
"ACE",
"Acetaminophen",
"Acetoacetate",
"Acetoacetic Acid",
"Acetone",
"Acetylcholine Receptor (AChR) Antibody",
"Acetylcholine Receptor Binding Antibody",
"Acetylcholine Receptor Blocking Antibody",
"Acetylcholine Receptor Modulating Antibody",
"Acetylsalicylic acid",
"AChE",
"AChR Antibody",
"Acid-Fast Bacillus (AFB)",
"cid-Fast Bacillus Smear and Culture and Sensitivity",
"Acid Phosphatase",
"aCL Antibody",
"ACPA",
"ACR",
"ACT",
"ACTA",
"ACTH",
"ACTH challenge test",
"ACTH stim test",
"ACTH Stimulation Test",
"Actin Antibody",
"Activated Clotting Time (ACT)",
"Activated Coagulation Time",
"Activated Partial Thromboplastin Time (APTT)",
"Activated Protein C",
"Activated Protein C Resistance",
"Activated Protein C Resistance V",
"Activated Protein S",
"Acute Promyelocytic Leukemia",
"Acute Viral Hepatitis Panel",
"AD",
"ADA",
"ADB",
"Adenosine Deaminase",
"ADH",
"ADN-B",
"ADNase-B",
"Adrenocorticotropic Hormone (ACTH)",
"Adrenocorticotropic Hormone (ACTH) stimulation test",
"Aerobic Wound Culture",
"AFB Smear and Culture",
"AFP",
"AFP Maternal",
"AFP-L3%",
"Agglutinins",
"AGPA",
"AIDS Screen",
"AIDS Test",
"ALA",
"Alanine Aminotransferase (ALT)",
"Alanine Transaminase",
"ALB",
"Albumin",
"Albumin (urine)",
"Albumin-to-Creatinine Ratio",
"Albumin/Globulin Ratio",
"Alcohol",
"Aldolase",
"Aldosterone and Plasma Renin Activity",
"Aldosterone and Renin",
"ALK",
"ALK Gene Fusion",
"ALK Gene Rearrangement",
"ALK Mutation (Gene Rearrangement)",
"Alk Phos",
"Alkaline Phosphatase (ALP)",
"Alkaline phosphatase isoenzymes",
"Alkp",
"Allergy Blood Testing",
"Allergy Screen",
"Alloantibody Identification",
"ALP",
"ALP Farctionation Test",
"Alpha Tryptase",
"Alpha-1 Antitrypsin",
"Alpha-fetoprotein (AFP) Tumor Marker",
"Alpha-fetoprotein, Total",
"ALT",
"Alzheimer Biomarkers",
"AMAS",
"Amethopterin",
"AMH",
"AMH Hormone Test",
"Aminoglycoside Antibiotics",
"Aminophylline",
"AML-M3",
"Ammonia",
"Amnio",
"Amniocentesis",
"Amniotic Fluid Analysis",
"Amy",
"Amylase",
"ANA",
"Anaerobic Wound Culture",
"ANCA Antibodies",
"Androstenedione",
"Angiotensin-Converting Enzyme (ACE)",
"Anion Gap",
"Anti-Beta-2 Glycoprotein 1",
"Anti-CCP",
"Anti-citrulline Antibody",
"Anti-cyclic Citrullinated Peptide",
"Anti-deamidated Gliadin Peptides",
"anti-DNA",
"Anti-DNase B",
"Anti-dsDNA",
"Anti-factor Xa Heparin",
"Anti-FXa",
"Anti-GPA",
"Anti-HAV",
"anti-HBc",
"anti-HBc IgM",
"anti-HBe",
"anti-HBs",
"Anti-HCV",
"Anti-Intrinsic Factor",
"Anti-Jo-1",
"Anti-Liver/Kidney Microsomal Antibodies Type 1",
"Anti-LKM-1",
"Anti-LKM1",
"Anti-Müllerian Hormone",
"Anti-P450 2D6 Antibody",
"Anti-parietal Cell Antibody",
"Anti-retroviral Drug Resistance Testing",
"Anti-Ribonucleoprotein",
"Anti-RNP",
"Anti-Saccharomyces cerevisiae Antibodies (ASCA)",
"Anti-Saccharomyces cerevisiae mannan antibodies",
"Anti-Sjögren Syndrome A",
"Anti-Sjögren Syndrome B",
"Anti-Sm (Sm)",
"Anti-Smooth Muscle Antibody",
"Anti-SmRNP",
"Anti-SSA",
"Anti-SSB",
"Anti-tissue Transglutaminase Antibody",
"Anti-Topoisomerase",
"Anti-TPO",
"Anti-U(1)RNP",
"Anti-Xa chromogenic",
"Antibiotic Susceptibility Testing",
"Antibodies to Saline-extracted Antigens",
"Antibody ID, RBC",
"Antibody Screen",
"Antibody to ds-DNA",
"Anticardiolipin Antibodies",
"Anticentromere Antibody",
"Anticytoplasmic Autoantibodies",
"Antidiuretic Hormone (ADH)",
"Antihistidyl Transfer RNA Synthase Antibodies",
"Antimicrobial Susceptibility",
"Antimicrosomal Antibody",
"Antimitochondrial Antibody and AMA M2",
"Antineutrophil Cytoplasmic Antibodies (ANCA, MPO, PR3)",
"Antinuclear Antibody (ANA)",
"Antinuclear Antibody Screen",
"Antiphospholipid Antibodies",
"Antireticulin Antibodies",
"Antistreptolysin O (ASO)",
"Antithrombin",
"Antithyroglobulin Antibody",
"Antithyroid Antibodies",
"AP",
"APA",
"APC",
"APC Resistance",
"APCA",
"APCR",
"APCRV",
"APS",
"Apo A",
"Apo A-I",
"Apo B",
"APOE Cardiac Risk",
"APOE e4",
"APOE Genotyping, Alzheimer Disease",
"APOE Genotyping, Cardiovascular Disease",
"Apolipoprotein B-100",
"aPTT",
"APTT",
"ARA",
"Arbovirus Nucleic Acid Amplification Tests",
"Arbovirus Serology IgM and IgG Antibodies",
"Arbovirus Testing",
"Arginine Vasopressin",
"Arterial Blood Gases",
"Arterial Blood Gases Analysis",
"Arthropod-borne Virus Testing",
"ARV Resistance Testing",
"ASA",
"ASCA",
"ASLO",
"ASMA",
"Aspartate Aminotransferase (AST)",
"Aspartate Transaminase",
"Aspirin",
"AST",
"AT 3",
"AT III",
"Autoantibodies",
"Autoimmune antibodies",
"Automated Immunoassays for Syphilis Antibodies",
"AVP",
"B Vitamins",
"B-cell Gene Clonality Molecular Genetic Tests",
"B-cell Immunoglobulin Gene Rearrangement",
"B2M",
"BAC",
"Bacterial Culture, respiratory",
"Bacterial Culture, stool",
"Bacterial Sputum Culture",
"Bacterial Wound Culture",
"BAL",
"Basic Metabolic Panel (BMP)",
"BCGR",
"BChE",
"Oncogene",
"BCR-ABL1",
"BCR/ABL",
"Beta 2GP1 Ab",
"Beta hCG",
"Beta Tryptase",
"Beta-2 Glycoprotein 1 Antibodies",
"Beta-2 Microglobulin Kidney Disease",
"Beta-2 Microglobulin Tumor Marker",
"Beta-hemolytic Strep Culture",
"Beta-hydroxybutyrate",
"Beta-hydroxybutyric Acid",
"Bicarb",
"Bicarbonate (Total CO2)",
"Bilirubin",
"Bioavailable Testosterone",
"biotin (B7)",
"Bismuth subsalicylate",
"BLL",
"Blood Alcohol Content",
"Blood Alcohol Level",
"Blood Cholesterol",
"Blood Clotting Factors",
"Blood Copper",
"Blood Creatinine",
"Blood Culture",
"Blood Culture (bacterial, fungal and/or AFB)",
"Blood Differential",
"Blood Film",
"Blood Gases",
"Blood Glucose",
"Blood Group",
"Blood Ketones",
"Blood Lead Level",
"Blood Lead Test",
"Blood Smear",
"Blood Sugar",
"Blood Typing",
"Blood Urea Nitrogen (BUN)",
"BMP",
"BNP and NT-proBNP",
"Body Fluid Testing",
"Bone Formation Markers",
"Bone Marrow Aspiration and Biopsy",
"Bone Marrow Examination",
"Bone Resorption Markers",
"Bone specific ALP",
"Bone Turnover Tests",
"Bone-specific Alkaline Phosphatase",
"Brain Natriuretic Peptide",
"BRCA",
"BRCA Gene Mutation Testing",
"Breast Cancer Gene 1",
"Breast Cancer Gene Expression Profiling",
"Breast Cancer Gene Expression Tests",
"Breast Cancer Multiparameter Gene Expression Tests",
"Breast Cancer Susceptibility Genes 1 and 2",
"BUN",
"BUN to Creatinine Ratio",
"C &amp; S",
"c-erbB-2",
"C-peptide",
"C-Reactive Protein (CRP)",
"C-telopeptide",
"C. difficile (culture, toxin gene NAAT or antigen test)",
"C1",
"C1q",
"C2",
"C3",
"C4",
"CA 125 Tumor Marker",
"CA 15-3",
"CA 19-9",
"CA-125",
"CA-Breast",
"CA-GI",
"Calcidiol (25-hydroxyvitamin D)",
"Calcifidiol (25-hydroxy-vitamin D)",
"Calcitonin",
"Calcitriol (1,25 dihydroxy-vitamin D)",
"Calcium",
"Calcofluor White Stain",
"Calculated Glomerular Filtration Rate",
"Calprotectin",
"CALR Gene Mutation Exon 9",
"CALR Mutation",
"cANCA",
"Cancer Antigen 19-9",
"Cancer Antigen-Breast",
"Cancer Antigen-GI",
"Cannabinoid or cannabis testing",
"Capillary Electrophoresis",
"Carbamazepine",
"Carbohydrate Antigen 19-9",
"Carbon Dioxide Content",
"Carcinoembryonic Antigen (CEA)",
"Cardiac Biomarkers",
"Cardiac CRP",
"Cardiac Fibrinogen",
"Cardiac Markers",
"Cardiac Risk Assessment",
"Cardiolipin Antibodies",
"Catecholamines",
"CBC",
"CBC with Differential",
"CCP Antibody",
"CCT",
"CD4 Count",
"CD4 Percent",
"CEA",
"Celiac Disease Antibody Tests",
"Cell-Free Fetal DNA",
"Centromere Antibody",
"Cerebrospinal Fluid (CSF) Testing",
"Ceruloplasmin",
"Cervical Cytology",
"Cervical Smear",
"CF DNA Analysis",
"CF Gene Mutation Panel",
"CF Molecular Genetic Testing",
"cffDNA",
"CFTR Mutation Analysis",
"CgA",
"cGFR",
"CGM",
"CH100",
"CH50",
"Chem 12",
"Chem 7",
"Chemistry Panel",
"Chemistry Panels",
"Chemistry Screen",
"Chickenpox and Shingles Tests",
"Chlamydia Culture",
"Chlamydia Nucleic Acid Amplification Test (NAAT or NAT) or DNA Probe",
"Chlamydia Testing",
"Chlamydia/GC STD Panel",
"Chloride",
"Cholecalciferol (Vitamin D3)",
"Cholesterol",
"Cholesterol Panel",
"Cholinesterase Tests",
"Chromogranin",
"Chromosome Analysis (Karyotyping)",
"Chromosome Karyotype",
"Chromosome Studies",
"CHS",
"Chymotrypsin",
"Chymotrypsin, fecal",
"Circulating Tumor Cell (CTC) Count",
"Circulating Tumor DNA (ctDNA)",
"Citrulline Antibody",
"CK",
"CK MB",
"CK-MB",
"Cl",
"CLO test",
"Clopidogrel (CYP2C19 Genotyping)",
"Clopidogrel Pharmacogenetic Testing",
"Clostridium difficile and C. diff Toxin Testing",
"Clotting Factors",
"CMP",
"CMV Blood Test",
"CMV IgG and IgM",
"CO2 Content",
"Coagulation Cascade",
"Coagulation Factors",
"Cobalamin",
"Cold Agglutinins",
"Cold Autoantibodies",
"Cold-Reacting Antibodies",
"Complement",
"Complete Blood Count (CBC)",
"Comprehensive Metabolic Panel (CMP)",
"Conjugated Bilirubin",
"Connecting Peptide Insulin",
"Continuous Glucose Monitoring",
"Copper",
"Coproporphyrin",
"Coronary Risk Panel",
"Corrected Reticulocyte",
"Corticotropin",
"Corticotropin test",
"Cortisol",
"Cosyntropin (Cortrosyn) stimulation test",
"CPK",
"CPK MB",
"CRCL",
"Creat",
"Creatine Kinase (CK)",
"Creatine Phosphokinase",
"Creatinine",
"Creatinine Clearance",
"Creatinine Clearance, Urine",
"Crit",
"CRP",
"CRP for heart disease",
"Cryocrit",
"Cryoglobulins",
"Cryoprotein",
"CSF Analysis",
"CSF Lactate",
"CSF Protein Electrophoresis",
"cTnI",
"cTnT",
"CTx",
"Cu",
"Culture – amniotic cells",
"Culture – amniotic fluid",
"Culture and Sensitivity",
"Culture, Throat",
"Cyclic Citrullinated Peptide Antibody",
"Cyclosporine",
"CYP2C19 Genotype",
"VKORC1 Genotype",
"Cystatin",
"Cystic Fibrosis (CF) Gene Mutations Testing",
"Cystic Fibrosis Genotyping",
"Cystic Fibrosis Transmembrane Conductance Regulator Mutation Analysis",
"Cytogenetic Analysis",
"Cytogenetics",
"Cytomegalovirus (CMV) Tests",
"D-dimer",
"Darkfield Microscopy",
"DAT",
"DCP",
"DD3",
"Delta-aminolevulinic Acid",
"Dengue Antibodies (IgG, IgM)",
"Dengue Fever Antibodies",
"Dengue Fever Testing",
"Dengue Fever Virus",
"Deoxypyridinoline",
"Des-gamma-carboxy prothrombin (DCP)",
"Dexamethasone Suppression Test",
"DGP",
"DHEA Sulfate",
"DHEA-SO4",
"DHEAS",
"Diabetes Autoantibodies",
"Diabetes Mellitus Autoantibody Panel",
"Diff",
"Differential Blood Count",
"Differential Display Code 3",
"Differential Slide",
"Digoxin",
"Dilute Russell Viper Venom Test",
"Direct Anti-human Globulin Test",
"Direct Antiglobulin Test",
"Direct Bilirubin",
"Direct Coombs Test",
"Direct LDL",
"Direct LDL Cholesterol",
"Direct LDL-C",
"Disaccharide Absorption Test",
"DLDL",
"Dopamine",
"Double stranded DNA Antibody",
"DPD",
"Drug Abuse Testing",
"Drug Resistance Testing",
"Drug Screen",
"Drug Test",
"Drug Testing",
"DRVVT",
"DST",
"E. coli",
"E. coli non-O157",
"E. coli O157",
"EBV Ab to Early Antigen D, EA-D IgG Ab",
"EBV Antibodies",
"EBV Nuclear Antigen Antibody EBNA-IgG Ab",
"EBV Viral Capsid Antigen Antibody (VCA) IgM, IgG Ab",
"eGFR",
"EGFR Mutation Testing",
"Elastase, stool",
"Electrolytes and Anion Gap",
"Electrophoresis",
"EMA",
"Emergency and Overdose Drug Testing",
"EML4-ALK Fusion Protein",
"ENA Panel",
"Endomysial Antibody",
"Enteric Pathogen Panel",
"Epidermal Growth Factor Receptor Mutation Analysis",
"Epinephrine",
"EPO",
"Epstein-Barr Virus (EBV) Antibody Tests",
"ER and PR Status",
"erb-b2 receptor tyrosine kinase",
"erbB-1",
"ERBB2",
"Ergocalciferol (Vitamin D2)",
"Erythrocyte Acetylcholinesterase",
"Erythrocyte Count",
"Erythrocyte Morphology",
"Erythrocyte Sedimentation Rate (ESR)",
"Erythropoietin",
"Essential Minerals",
"Estimated Glomerular Filtration Rate (eGFR)",
"Estradiol (E2)",
"Estriol (E3)",
"Estrogen Fractions/fractionated",
"Estrogen Receptor, Progesterone Receptor Breast Cancer Testing",
"Estrogen Receptors",
"Estrogenic Hormones",
"Estrogens",
"Estrone (E1)",
"Ethanol",
"Ethyl Alcohol",
"EtOH",
"Extractable Nuclear Antigen Antibodies (ENA) Panel",
"F-Actin Antibody",
"Factor Assays",
"Factor I",
"Factor I Assay",
"Factor II",
"Factor II 20210",
"Factor II Mutation",
"Factor IX",
"Factor V",
"Factor V Leiden Mutation and PT 20210 Mutation",
"Factor V R506Q",
"Factor VII",
"Factor VIII",
"Factor X",
"Factor XI",
"Factor XIII",
"FANA",
"Fasting Blood Glucose",
"Fasting Blood Sugar",
"Fasting Insulin",
"Fasting Lipid Panel",
"Fasting Plasma Glucose",
"Fat Stain Oil Red O",
"Fat, Fecal Qualitative or Quantitative",
"Fat, Feces",
"FBG",
"FBS",
"Fe Tests",
"FE-1",
"Fecal Calprotectin",
"Fecal Elastase-1",
"Fecal Fat",
"Fecal Immunochemical Test",
"Fecal Immunochemical Test and Fecal Occult Blood Test",
"Fecal Lactoferrin",
"Fecal Occult Blood Test",
"Fecal Pancreatic Elastase",
"Fecal WBC Non-microscopic",
"Feces Culture",
"FEP",
"Ferritin",
"Ferritin, serum",
"Fetal Fibronectin (fFN)",
"Fetal Lung Maturity Tests",
"fFN",
"Fibrin Degradation Fragment",
"Fibrinogen",
"Fibrinogen Activity (Functional)",
"Fibrinogen Antigen ",
"FIP1-like-1/platelet-derived growth factor alpha",
"FIP1L1-PDGFRA",
"FIP1L1-PDGFRA Fusion by FISH or RT-PCR",
"First Trimester Screening",
"FIT",
"FK506",
"FLC",
"Flu Test",
"Fluorescent Antinuclear Antibody",
"Fluorescent Treponemal Antibody Absorption Test",
"FOBT",
"Folacin",
"Folic Acid",
"Follicle-stimulating Hormone (FSH)",
"Follicle-stimulating Hormone, serum or urine",
"Follitropin",
"FPG",
"Fractionated Catecholamines",
"Fractionated Metanephrines",
"Fractionated Plasma Free Metanephrines (Metanephrine and Normetanephrine)",
"Fragment D-dimer",
"Free Catecholamines, plasma and urine",
"Free Copper",
"Free Cortisol",
"Free Erythrocyte Protoporphyrin",
"Free Light Chains",
"Free Metanephrines, urine",
"Free PSA",
"Free T3",
"Free T4",
"Free Testosterone",
"Free Thyroxine",
"Free Thyrocine Index",
"Free Triiodothyronine",
"Free Valproic Acid",
"Fructosamine",
"FSH",
"FT3",
"FT4",
"FT4 Index",
"FTA-ABS",
"FTI",
"Functional Antithrombin III",
"Fungal Molecular Tests",
"Fungal Smear, Culture, Antigen and Antibody Tests",
"Fungal Tests",
"G-6-PD",
"G6PD",
"Gamma-Glutamyl Transferase (GGT)",
"Gamma-Glutamyl Transpeptidase",
"Gamma-GT",
"Gastric Parietal Cell Antibody",
"Gastrin",
"Gastrointestinal Pathogens Panel",
"GBS",
"GC Test",
"GDH",
"Gel Electrophoresis",
"Genetic Tests for Targeted Cancer Therapy",
"Gentamicin",
"German Measles",
"gFOBT",
"GFR",
"GGTP",
"GH",
"GI Panel",
"Globulin",
"Glomerular Filtration Rate, Estimated",
"Glucose Tests",
"Glutamate dehydrogenase test",
"Glycated Hemoglobin",
"Glycated Serum Protein",
"Glycohemoglobin",
"Glycosylated Hemoglobin",
"Gonorrhea NAAT or NAT",
"Gonorrhea Testing",
"GOT",
"GPT",
"Gram Stain",
"Gram’s Stain",
"Group A streptococcus (GAS)",
"Group B Beta-hemolytic Streptococcus",
"Group B Strep",
"Group B Streptococcus",
"Growth Hormone",
"Growth Hormone Stimulation Test",
"Growth Hormone Suppression Test",
"GSP",
"GTP",
"GTT",
"Guaiac-based FOBT",
"H and H (Hemoglobin and Hematocrit)",
"H. pylori",
"H. pylori stool antigen",
"H1N1",
"Haptoglobin",
"HAV-Ab IgG",
"HAV-Ab IgM",
"HAV-Ab total",
"Hb",
"Hb ELP",
"Hb IEF",
"Hb S",
"HbA1c",
"HBeAg",
"HBsAg",
"HBV DNA",
"HBV Tests",
"hCG Pregnancy",
"hCG Tumor Marker",
"HCO3",
"Hct",
"HCV-PCR",
"HCV-RNA",
"HDL",
"HDL Cholesterol",
"HDL-C",
"HE4",
"Heavy Metals",
"Helicobacter pylori (H. pylori) Testing",
"Hematocrit",
"Hemoccult",
"Hemoglobin",
"Hemoglobin A1c",
"Hemoglobin Evaluation",
"Hemoglobin Fractionation",
"Hemoglobin S",
"Hemoglobin-binding Protein",
"Hemoglobinopathy Evaluation",
"Hemogram",
"Hep B",
"Heparin Activity",
"Heparin Anti-Xa",
"Heparin Associated Antibody",
"Heparin Induced Antibody",
"Heparin Level",
"Heparin-induced Thrombocytopenia PF4 Antibody",
"Heparin-PF4 Antibody",
"Hepatic Copper",
"Hepatitis A Testing",
"Hepatitis B Core Antibody",
"Hepatitis B e Antibody",
"Hepatitis B e Antigen",
"Hepatitis B Surface Antibody",
"Hepatitis B Surface Antigen",
"Hepatitis B Testing",
"Hepatitis C Antibody",
"Hepatitis C Testing",
"Hepatitis C Viral Load",
"Hepatitis Panel",
"Hepatitis Screening Panel",
"HER-1",
"HER2",
"HER2/neu ",
"Herpes Culture",
"Herpes Simplex Viral Culture",
"Herpes Testing",
"Herpes Zoster",
"HES/Leukemia",
"Heterophile Antibody Test",
"Hg (chemical symbol)",
"Hgb",
"HgbA1c",
"Hgb S",
"HGH",
"HHV1",
"HHV2",
"HIAA",
"High-risk HPV",
"High-sensitivity C-reactive Protein (hs-CRP)",
"high-sensitivity troponin",
"Histamine",
"Histocompatibility Testing",
"Histone Antibody",
"Histone Autoantibodies",
"HIT Antibody",
"HIT PF4 Antibody",
"HIV Antibody and HIV Antigen (p24)",
"HIV Antiretroviral Drug Resistance Testing, Genotypic",
"HIV Nucleic Acid Amplification Test (NAAT or NAT): HIV by PCR",
"HIV RNA Test",
"HIV Screening Tests",
"HIV Serology",
"HIV Viral Load",
"HLA Crossmatching",
"HLA Testing",
"HLA-B27",
"HLA-B27 Antigen",
"Homocysteine",
"Homocysteine Cardiac Risk",
"Hormone Receptor Status",
"Hp",
"HPT",
"HPV DNA",
"HPV Genotypes 16 and 18",
"HPV mRNA",
"hrHPV",
"hs-troponin",
"hsCRP",
"HSV by PCR",
"HSV DNA",
"HSV-1",
"HSV-1 or HSV-2 IgM or IgG",
"HSV-2",
"HTLV-I/II Antibodies",
"HTLV-I/II by PCR",
"Human Calcitonin",
"Human epidermal growth factor receptor 2",
"Human Epididymis Protein 4 (HE4)",
"Human Fecal Elastase-1",
"Human Growth Hormone",
"Human Papillomavirus (HPV) Test",
"Human T-cell Lymphotropic Virus (HTLV) Testing",
"Human T-Lymphotropic Virus Types I/II Antibodies",
"HTLV-I/II by PCR",
"Hydrogen Breath Test",
"IAT",
"IBC",
"ICSH",
"IF Antibody",
"IF Antibody Type 1 or Type 2",
"IFE",
"iFOBT",
"IgA",
"IGF-1",
"IgG",
"IgM",
"IGRA TB Test",
"IL-6",
"Immune Checkpoint Inhibitor",
"Immunoglobulin A",
"Immunoglobulin A, Total",
"Immunoglobulin G",
"Immunoglobulin Gene Rearrangement",
"Immunoglobulin M",
"Immunoglobulins (IgA, IgG, IgM)",
"Immunophenotyping",
"Immunoreactive Trypsin",
"Immunoreactive Trypsinogen (IRT)",
"Immunosubtraction Electrophoresis",
"Indirect Anti-human Globulin Test",
"Indirect Antiglobulin Test",
"Indirect Bilirubin",
"Indirect Coombs Test",
"Influenza Rapid Antigen Test, Type A and B Antigen Detection",
"Influenza Tests",
"Influenza Viral Culture",
"Influenza Virus by PCR",
"INR ",
"Insulin",
"Insulin C-peptide",
"Insulin-like Growth Factor-1 (IGF-1)",
"Intact hCG",
"Intact PTH",
"Interleukin-6",
"Intermittently Scanning CGM",
"Interstitial Cell Stimulating Hormone",
"Interstitial Glucose Monitoring",
"Intrinsic Factor Antibody",
"Intrinsic Factor Binding Antibody",
"Intrinsic Factor Blocking Antibody",
"Ionized Calcium",
"Iontophoretic Sweat Test",
"Iron",
"Iron Binding Capacity",
"Iron Indices",
"Iron Tests",
"IRT",
"Islet Autoantibodies in Diabetes",
"Islet Cell Cytoplasmic Autoantibodies (ICA)",
"Insulin Autoantibodies (IAA)",
"Glutamic Acid Decarboxylase Autoantibodies (GADA)",
"GAD65 Autoantibodies",
"Insulinoma-Associated-2 Autoantibodies (IA-2A)",
"Zinc Transporter-8 Autoantibodies (ZnT8A)",
"Isoenzyme Test",
"JAK2 Mutation",
"JAK2 V617F ",
"Janus Kinase 2",
"Joint Fluid Analysis ",
"K",
"K-Ras",
"Kappa and Lambda Free Light Chains",
"Karyotype",
"Ketone Bodies",
"Ketone Body",
"Kidney Function Panel",
"Kidney Panel",
"Kidney Stone Analysis",
"Kidney Stone Risk Panel",
"Kidney Stone Testing",
"KOH Prep",
"KRAS Mutation",
"LA",
"LA Sensitive PTT",
"LAC",
"Lactate",
"Lactate Dehydrogenase (LD)",
"Lactic Acid",
"Lactoferrin",
"Lactose Breath Test",
"Lactose Intolerance Test",
"Lactose Tolerance Tests",
"Latent Tuberculosis Infection Test",
"LDH",
"LDL",
"LDL Cholesterol",
"LDL D",
"LDL Particle Size or Number",
"LDL Particle Testing (LDL-P)",
"Subclasses",
"LDL Subfractions",
"LDL-C",
"Lead",
"Legionella pneumophila",
"Legionella Testing",
"Legionnaires Disease or Legionellosis Testing",
"Leptin",
"Leukocyte Count",
"Leukocyte Differential",
"Leukocyte Differential Count",
"Levetiracetam",
"LFTs",
"LH",
"Lipase",
"Lipid Panel",
"Lipid Profile ",
"Lipid Test",
"Lipoprotein (a)",
"Lipoprotein little a",
"Lipoprotein-associated Phospholipase A2",
"Liquid Biopsy",
"Lithium",
"Liver Function Tests",
"Liver Kidney Microsomal Type 1 Antibodies",
"Liver Kidney Microsome Type 1 Antibodies (Cytochrome P450 2D6 Antibodies)",
"Liver Panel",
"Liver Profile",
"LKM1 Antibodies",
"Low-Density Lipoprotein Cholesterol",
"Low-Density Lipoprotein Subfraction Profile",
"Lp-PLA2",
"Lp(a)",
"LPS",
"Lupus Anticoagulant Testing",
"Lupus Inhibitor",
"Luteinizing Hormone (LH)",
"Luteinizing Hormone, serum or urine",
"Lutropin",
"Lyme Antibodies Detection",
"Lyme Antibodies IgM/IgG by Western Blot",
"Lyme Disease Tests",
"Lytes",
"Mag",
"Magnesium",
"Magnesium salicylate",
"Mantoux",
"Manual Differential",
"Marijuana (THC) Testing",
"Mast Cell Tryptase",
"Maternal Serum AFP",
"Maternal Serum Screen",
"Maternal Serum Screening, Second Trimester",
"Mature Tryptase",
"Mean Platelet Volume",
"Measles and Mumps Tests",
"Measles Antibody IgM, IgG",
"Measles Virus by RT-PCR",
"Mercury",
"Mercury, Urine and Blood",
"Metanephrines",
"Methicillin-resistant Staphylococcus aureus",
"Methotrexate",
"Methyl salicylate",
"Methylenetetrahydrofolate Reductase Mutations, C677T and A1298C",
"Methylmalonic Acid",
"Mg",
"MHA-TP",
"Microalbumin",
"Microhemagglutination Assay",
"Microminerals",
"Micronutrients",
"MIF",
"MIH",
"MIS",
"Mitochondrial Antibody",
"MMA",
"MMF",
"Modified Russell Viper Venom Test",
"Mononuclear Heterophile Test",
"Mononucleosis (Mono) Test",
"Mononucleosis Spot Test ",
"MonkeyPox",
"Monospot",
"MPA",
"MPO",
"MPO-ANCA",
"MPV",
"MRSA Screening",
"MRVVT",
"msAFP",
"MSAFP",
"MTHFR DNA Testing",
"MTHFR Mutation",
"MTX",
"Müllerian Inhibiting Factor",
"Müllerian-inhibiting Hormone",
"Müllerian-Inhibiting Substance",
"Multi-pathogen Molecular Assay",
"Multiple Marker Test",
"Multiplex Respiratory Panel",
"Mumps Antibody IgM, IgG",
"Mumps Virus by RT-PCR",
"Muscle nicotinic Acetylcholine Receptor (AChR) Binding Antibody",
"Myasthenia Gravis Antibodies",
"Mycobacteria Smear and Culture",
"Mycobacterium tuberculosis Nucleic Acid Amplification Test",
"Mycology Tests",
"Mycophenolate Mofetil",
"Mycophenolate Sodium",
"Mycophenolic Acid",
"Mycoplasma",
"Mycoplasma by PCR",
"Mycoplasma Culture",
"Mycoplasma pneumoniae IgG and IgM Antibodies",
"Mycoplasma pneumoniae Culture",
"Mycoplasma Culture, genital source;",
"Mycoplasma DNA Testing",
"Myeloperoxidase Antibodies",
"Myoglobin",
"N-telopeptide",
"Na",
"Native double-stranded DNA Antibody",
"Natriuretic Peptides",
"Neisseria gonorrhoeae, Nucleic Acid Amplification Test, Culture, Gram Stain, DNA Probe",
"Neonatal Bilirubin",
"NH3",
"niacin (B3)",
"Nicotine",
"Nicotine and Cotinine",
"NIPS",
"NMR Particle Test",
"Non-fasting Lipid Panel",
"Non-HDL",
"Non-HDL-C",
"Non-High Density Lipoprotein Cholesterol",
"Non-Invasive Prenatal Screening",
"Norepinephrine",
"Normetanephrine",
"NTx",
"O&amp;P",
"Occult Blood Test, Fecal",
"OGTT",
"Opioid Testing",
"Oral Glucose Tolerance Test",
"Oral Lactose Tolerance",
"Osmolality (serum, plasma, urine, stool)",
"Osmolality and Osmolal Gap",
"Osmotic Gap",
"Osteocalcin",
"Ova and Parasite Exam",
"P",
"P1NP",
"p24 Antigen",
"Packed Cell Volume",
"PAF-AH",
"pANCA",
"Pancreatic Elastase",
"pantothenic acid (B5)",
"PAP",
"Pap Smear (Pap Test)",
"Paracetamol",
"Parathormone",
"Parathyroid Hormone (PTH)",
"Parietal Cell Antibody",
"Parotitis",
"Partial Thromboplastin Time (PTT, aPTT)",
"Parvo B19",
"Parvovirus",
"Parvovirus B19",
"PBG",
"PCA3",
"PCHE",
"PCT",
"PCV",
"PD-L1",
"PDGFRA-FIP1L1 gene rearrangement",
"PDW",
"Pericardial Fluid Analysis",
"Peripheral Differential",
"Peripheral Smear",
"Peritoneal Fluid Analysis",
"Pertussis Tests",
"PFA",
"PFT",
"PGSN",
"Pharmacogenetic Tests",
"Phenobarbital",
"Phenytoin",
"Philadelphia Chromosome",
"Phosphate",
"Phospholipid Antibodies",
"Phosphorus",
"PIVKA II (protein induced by vitamin K absence or antagonists II)",
"PLAC",
"Plasma Cholinesterase",
"Plasma Free Metanephrines",
"Plasma Ketones",
"Plasma Metanephrines",
"Plasma Osmolality",
"Plasma Total Homocysteine",
"Platelet Aggregation Studies",
"Platelet Count",
"Platelet Distribution Width",
"Platelet Function Assay",
"Platelet Function Tests",
"Platelet-activating Factor Acetylhydrolase",
"Pleural Fluid Analysis",
"PLT",
"PML-RARA ",
"PML-RARA t(15;17)(q22;q12) PO4",
"Poison Testing",
"Porphobilinogen",
"Porphyrin Tests",
"Potassium",
"Potassium Hydroxide Preparation",
"PPD",
"PR3",
"PR3-ANCA",
"PRA",
"Prealbumin",
"Pregnancy Test (hCG)",
"Pregnancy-associated plasma protein-A (PAPP-A) with hCG, total or free beta subunit and nuchal translucency ultrasound",
"Pregnenolone",
"Prenatal Group B Strep (GBS) Screening",
"Presenilin 1 Gene",
"PRL",
"proBNP",
"Pro Time",
"Procalcitonin",
"Procollagen Type 1 N-Terminal Propeptide",
"Progesterone",
"Progesterone Receptors",
"Programmed Cell Death -Ligand 1 (PD-L1)",
"Proinsulin C-peptide",
"Prolactin",
"Prostate Specific Antigen (PSA)",
"Prostatic Acid Phosphatase",
"Prostatic Isoenzyme",
"Protein C",
"Protein C Activity",
"Protein C and Protein S",
"Protein C Level",
"Protein Electrophoresis",
"Immunofixation Electrophoresis",
"Protein ELP",
"Protein S",
"Protein S Activity",
"Protein S Level",
"Proteinase 3 Antibodies",
"Prothrombin Time and International Normalized Ratio (PT/INR)",
"Protime",
"Protoporphyrin",
"PS1",
"PSA",
"PSEN1",
"Pseudocholinesterase",
"PT",
"PT G20210A",
"PTT",
"PTT-LA",
"Purified Protein Derivative",
"Pyridinium Crosslinks",
"pyridoxal phosphate (B6)",
"Quad Screen",
"Quadruple Marker Test",
"Qualitative hCG",
"Qualitative or Quantitative Stool Fat",
"QuantiFERON®-TB Gold In-Tube (QFT-GIT)",
"QuantiFERON®-TB Gold Plus (QFT-Plus)",
"Quantitative hCG",
"Quantitative IgE",
"Quantitative Serum Free Light Chains with Ratio",
"Rapamycin",
"Rapid Antigen Detection Test (RADT)",
"Rapid Flu Test",
"Rapid Influenza Diagnostic Test",
"Rapid Plasma Reagin",
"Rapid Strep Test",
"Rapid urease test (RUT) for H. pylori",
"RBC Ab ID",
"RBC Antibody Screen",
"RBC Cholinesterase",
"RBC Count",
"RBC Folate",
"RBC G6PD test",
"RBC Morphology",
"Real-time CGM",
"Red Blood Cell (RBC) Antibody Identification",
"Red Blood Cell (RBC) Antibody Screen",
"Red Blood Cell Count (RBC)",
"Red Blood Cell Indices",
"Red Blood Cell Morphology",
"Red Cell Cholinesterase",
"Red Cell Indices",
"Red Count",
"Renal Calculus Analysis",
"Renal Function Panel",
"Renal Function Test",
"Renal Panel",
"Renal Stone Risk Panel",
"Respiratory Culture",
"Respiratory Pathogens Panel",
"Respiratory Syncytial Virus (RSV) Testing",
"Respiratory Virus Profile",
"Retic Count",
"Reticulocyte Count",
"Reticulocyte Index",
"Reticulocyte Percent",
"Reticulocyte Production Index",
"Reticulocytes",
"Retinol",
"RF",
"Rh Factor",
"Rheumatoid Factor (RF)",
"riboflavin (B2)",
"Ristocetin Cofactor",
"RP Panel",
"RPI",
"RPR",
"RSV",
"Rubella Antibodies, IgM and IgG",
"Rubella Test",
"Rubeola",
"S182",
"Saccharomyces cerevisiae",
"Antibodies, IgG or IgA",
"SACE",
"Salicylates",
"Salivary Cortisol",
"Salsalate",
"Scl-70",
"Scleroderma Antibodies",
"sdLDL",
"Sed Rate",
"Sedimentation Rate",
"Semen Analysis",
"Seminal Fluid Analysis",
"Sensitivity Testing",
"Serine Protease 3",
"Serotonin",
"Serotonin Metabolite",
"Serum Albumin",
"Serum Angiotensin Converting Enzyme",
"Serum Creatinine",
"Serum Fe",
"Serum Ferritin",
"Serum Free Light Chains",
"Serum Glutamic-Oxaloacetic Transaminase",
"Serum Glutamic-Pyruvic Transaminase",
"Serum Iron",
"Serum Iron-Binding Capacity",
"Serum Ketones",
"Serum Myoglobin",
"Serum Osmolality",
"Serum Protein Electrophoresis",
"Serum Transferrin Receptors",
"Serum Trypsinogen",
"Serum Urate",
"Hormone Binding Globulin (SHBG)",
"SFLC",
"SGOT",
"SGPT",
"SHBG",
"Shiga toxin-producing Escherichia coli",
"Sickle Cell Screen",
"Sickle Cell Tests",
"Siderophilin",
"Sirolimus",
"SMA",
"SMA 12",
"SMA 20",
"SMA 7",
"SMAC (somewhat outdated terms)",
"SMAC7",
"Smith Antibody",
"Smooth Muscle Antibody (SMA) and F-actin Antibody",
"SMRP",
"Sodium",
"Sodium salicylate",
"Soluble Mesothelin-Related Peptides",
"Soluble Transferrin Receptor",
"Somatomedin C",
"Somatotropin",
"SPE",
"SPEP",
"Sperm Analysis",
"Sperm Count",
"Spinal Fluid Analysis",
"Sports Doping Tests",
"Sputum Culture, Bacterial",
"SSA (Ro)",
"SSB (La)",
"STB",
"STEC",
"sTfR",
"Stool Calprotectin",
"Stool Chymotrypsin",
"Stool Culture",
"Stool Elastase",
"Stool Lactoferrin",
"Stool Lipids",
"Stool Occult Blood",
"Strep Group B",
"Strep Throat Test",
"Streptococcal Screen",
"Streptococcus agalactiae",
"Substance Abuse Testing Sweat Chloride",
"Sweat Chloride Test",
"Sweat Electrolytes",
"Syndromic Multiplex Panel",
"Synovial Fluid Analysis",
"Syphilis Detection Tests",
"Syphilis Tests",
"T-cell Gene Clonality",
"T-Cell Receptor Gene Rearrangement",
"T-helper Cells",
"T-Spot®",
"T. gondii",
"T. vaginalis",
"T3 (Free and Total)",
"T4 Count",
"T4, Free",
"T7",
"TAC",
"Tacrolimus",
"Tartrate-resistant Acid Phosphatase",
"Tau Protein and Beta Amyloid",
"TB Culture and Sensitivity",
"TB NAAT",
"TB QuantiFERON®-TB Gold (QFT-G)",
"TB Skin Test",
"TBII",
"TBIL",
"TCGR",
"TCO2",
"TCR Gene Rearrangement",
"TDM",
"TeBG",
"Testosterone-estrogen Binding Globulin",
"Tetrahydrocannabinol (THC) testing",
"TfR",
"TFT",
"Tg",
"TG",
"TgAb",
"TGB",
"Theophylline and Caffeine",
"Therapeutic Drug Monitoring",
"thiamine or thiamin (B1)",
"Thiopurine methyltransferase (TPMT)",
"Three-day Measles",
"Throat Culture",
"Thrombin Clotting Time",
"Thrombin Time",
"Thrombocyte Count",
"Thymotaxin",
"Thyrocalcitonin",
"Thyroglobulin",
"Thyroid Antibodies",
"Thyroid Autoantibodies",
"Thyroid Function Tests",
"Thyroid Microsomal Antibody",
"Thyroid Panel",
"Thyroid Peroxidase Antibody",
"Thyroid Profile",
"Thyroid Stimulating Immunoglobulin",
"Thyroid-stimulating Hormone (TSH)",
"Thyroperoxidase Antibody",
"Thyrotropin",
"Thyrotropin Receptor Antibodies",
"Thyroxine",
"Thyroxine-binding Prealbumin",
"Thyroxine Screen",
"TIBC",
"Tissue Typing",
"TnI",
"TnT",
"TORCH",
"TORCH Panel",
"Total AFP",
"Total beta hCG",
"Total Bilirubin",
"Total Calcium",
"Total CK",
"Total CO2",
"Total Complement",
"Total hCG",
"Total IgE",
"Total Immunoglobulins",
"Total Protein",
"Total Protein",
"Albumin-Globulin (A/G) Ratio",
"Total PSA",
"Total Testosterone",
"Total Triiodothyronine",
"Tox Screen",
"Toxic Metals",
"Toxicology Assays",
"Toxicology Screen",
"Toxoplasma gondii",
"Toxoplasmosis Testing",
"TP",
"TPMT Genotype",
"TPMT Phenotype",
"TPMT RBC",
"TPO",
"TPPA",
"TRAb",
"Trace Minerals",
"Trace Elements",
"Transferrin and Iron-binding Capacity (TIBC, UIBC)",
"Transferrin Receptor",
"TRAP",
"Treponema pallidum",
"by PCR",
"Particle Agglutination Assay",
"Trichomonas Testing",
"TRIG",
"Triglycerides",
"Triiodothyronine",
"Triple Screen",
"Triple Test",
"Troponin",
"Trypsin-like Immunoreactivity",
"Tryptase",
"TSH",
"TSH Receptor Antibody",
"TSI",
"TT",
"tTG",
"Tumor Markers",
"UA",
"UACR",
"UIBC",
"Unconjugated Bilirubin",
"UPCR",
"UPE",
"UPEP",
"Urea breath test",
"Urea Nitrogen",
"Ureaplasma Culture",
"Uric Acid",
"Urinalysis",
"Urinary Cortisol",
"Urinary Stone Analysis",
"Urine Albumin and Albumin to Creatinine Ratio",
"Urine Analysis",
"Urine C and S",
"Urine Calcium",
"Urine Citric Acid (Citrate)",
"Urine Copper",
"Urine Creatinine",
"Urine Culture",
"Urine Culture and Sensitivity",
"Urine Drug Testing",
"Urine Glucose",
"Urine Homocysteine",
"Urine Metanephrines",
"Urine Metanephrines, Total and Fractionated",
"Urine Myoglobin",
"Urine Osmolality",
"Urine Oxalate",
"Urine Protein and Urine Protein to Creatinine Ratio",
"Urine Protein Electrophoresis",
"Urine Protein to Creatinine Ratio",
"Urine Supersaturation Profile",
"Urine Test",
"Urine Total Protein",
"Urine Uric Acid",
"Uroporphyrin",
"Valproate",
"Valproic Acid",
"Vancomycin",
"Vanillylmandelic Acid (VMA)",
"VAP",
"Varicella Zoster Virus by Culture, PCR, or DFA",
"Vasopressin",
"VDRL",
"Venereal Disease Research Laboratory",
"Viral Hepatitis Panel",
"Vitamin A",
"Vitamin B complex",
"Vitamin B12",
"Vitamin B12 and Folate",
"Vitamin B9",
"Vitamin D Tests",
"Vitamin K",
"VLDL",
"VLDL Cholesterol",
"VLDL-C",
"VMAU",
"von Willebrand Factor",
"von Willebrand Panel",
"VWF:Ag",
"VWF:RCo",
"VZV",
"VZV Antibodies IgG and IgM",
"Warfarin Sensitivity Genotyping",
"Warfarin Sensitivity Testing",
"WBC Count",
"WBC Count Differential",
"West Nile Virus Testing",
"West Nile Virus, IgM and IgG Antibodies and Nucleic Acid Amplification Tests",
"Wet Prep",
"White Blood Cell (WBC) Differential",
"White Blood Cell Count (WBC)",
"White Count",
"Whooping Cough Tests",
"Widal Test",
"Wintrobe Sedimentation Rate",
"WNV by NAAT",
"WNV Tests",
"Xa Inhibition",
"Xylose Absorption Test",
"Xylose Tolerance Test ",
"Zika Antibody Test",
"Zika PRNT",
"Zika RT-PCR Test",
"Zika Virus Testing",
"Zinc Protoporphyrin"]