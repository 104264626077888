import React, { Component } from 'react'
import { NavMenu } from './NavMenu';
import { Users } from '../Account/Users';
import PatientProfile from '../DE/PatientProfile';
import PatientPayments from '../DE/SessionControls/PatientPayments';
import PatientSearch from '../PatientSearch';
import ChangePassword from '../Account/ChangePassword';


class Owner extends Component {
    App = undefined;
    constructor(props) {
        super(props);
        this.App = props.App;       
     
       
       this.navigtion= [
        { index: 0, title: "Search", component:<PatientSearch App={this.App} />},        
        { index: 1, title: "Profiles", component:<PatientProfile App={this.App} />},
        { index: 2, title: "Payments", component:<PatientPayments App={this.App} />},
        { index: 3, title: "Users", component:<Users App={this.App} />},
        { index: 4, title: "Password", component:<ChangePassword App={this.App} />},
        // { index: 4, title: "Branches", component:<SWNWBranch App={this.App}  />  },   
        // { index: 5, title: "Treatment", component:<SWNWTreatment App={this.App}  />  },        
        ]

    }
    

    render() {
        return <div>
            <NavMenu App={this.App} nav={this.navigtion}   />            
        </div>
    }
}

export default Owner;

