import { useState } from "react"
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

export function Preg(props) {
    const Session = props.Session;
    if(!Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData.SwNw54)
    {
        Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData.SwNw54 = {PN:0, PW: 0, EX: new Date() };
    }
    if(!Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData.SwNw54.PN)
    {
        Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData.SwNw54.PN = 0;
    }
    const [PN, setPN] = useState(Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData.SwNw54.PN);
    const [PW, setPW] = useState(Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData.SwNw54.PW);    
    const [EX, setEX] = useState(Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData.SwNw54.EX);
    const [IsOpen, setIsOpen] = useState(false);

    const arrv = []
for (let index = 0; index < 43; index++) {
    arrv.push(index);
    
}

    return <div hidden={props.hidden} name={props.Target}  className="form-control">
         <div className="m-2" >
            <div className="form-group m-1">
            <label htmlFor="Date">Pregnancy Number</label><input type="number" className="form-control " id="PN" value={PN} name="PN" onChange={(e) => {
              
                setPN(e.target.value)
                Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData["SwNw54"].PN = e.target.value;
                Session.setState({ "SessionObject": Session.state.SessionObject });
                Session.StorageSave();

            }} /></div>
        </div>
        <div className=" m-2" >
        <label htmlFor="Date">Pregnancy week</label>    <ButtonDropdown direction="right" toggle={() => { setIsOpen(!IsOpen) }}
                isOpen={IsOpen}>
                <DropdownToggle className="bg-light text-dark" caret>
                    {PW}
                </DropdownToggle>
                <DropdownMenu style={{ height: "200px", overflowY: "scroll" }}>
                    {arrv.map(a => <DropdownItem key={a} data-ix={a} onClick={(e) => { 
                        setPW(e.target.getAttribute("data-ix")); 
                        Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData["SwNw54"].PW = e.target.getAttribute("data-ix");
                        setEX(new Date(new Date(Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].Date).setDate((41 - e.target.getAttribute("data-ix")) * 7)).toISOString().substring(0, 10));
                        Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData["SwNw54"].EX =new Date(new Date(Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].Date).setDate((41 - e.target.getAttribute("data-ix")) * 7)).toISOString().substring(0, 10);
                        setIsOpen(false); 
                        Session.setState({ "SessionObject": Session.state.SessionObject }); 
                        Session.StorageSave(); }}>{a}</DropdownItem>)}
             

                </DropdownMenu>
            </ButtonDropdown>    
            
            
        </div>
        <div className="m-2" >
            <div className="form-group m-1">
            <label htmlFor="Date">Estimated date of delivery</label><input type="date" className="form-control " id="Date" value={new Date(EX).toISOString().substring(0, 10)} name="Date" onChange={(e) => {
                if (e.target.valueAsDate === null || e.target.valueAsDate === undefined) {
                    return;
                }
                setEX(e.target.valueAsDate)
                Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData["SwNw54"].EX = e.target.valueAsDate;
                Session.setState({ "SessionObject": Session.state.SessionObject });
                Session.StorageSave();

            }} /></div>
        </div>
    </div>
}
export default Preg;