import { Component, useState } from "react";
import { ButtonDropdown,  DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

function NewUser(props) {
    const [dropdownOpen1, set1Open] = useState(false);
    const [SelectedOperation1, setSelectedOperation1] = useState("Role");
    const [Message, setMessage] = useState("");
    const [UserData, setUserData] = useState({});

    const HandleChange = (e) => {
        UserData[e.target.name] = e.target.value;
        setUserData(UserData);

    }
    const Select = (e) => {
        setSelectedOperation1(e.target.innerText);
        UserData.O = parseInt(e.target.value);
        setUserData(UserData);


    }

    const Check = () => {
        if (UserData.O !== 0 && UserData.O !== 1 && UserData.O !== 2 && UserData.O !== 3) {

            setMessage("Role : Please select");
            return;
        }
        if (!UserData.N || UserData.N === "") {
            setMessage("User Name : Please insert");
            return;
        }
        const reN = /^[[A-Za-z0-9]+$/;
        if (!reN.test(UserData.N)) {
            setMessage("User Name : chars & numbers only, Please remove spaces or special chars");
            return;
        }
        console.log(UserData.M);
        if (!UserData.M || UserData.M === "") {
            setMessage("Mobile : Please insert");
            return;
        }
        const re = /^[[0-9]+$/;
        if (!re.test(UserData.M)) {
            setMessage("Mobile : only Numbers");
            return;
        }
        if (UserData.M.length < 11) {
            setMessage("Mobile : not lesst than 11 to 15 ");
            return;
        }
        if (!UserData.P || UserData.P === "") {
            setMessage("Password : Please insert, All chars Allowd");
            return;
        }
        if (UserData.P.length < 6) {
            setMessage("Password : Not less than 6 Chars");
            return;
        }
        props.OnSave(UserData);
    }
    return <div>

        <div className="input-group mb-3">
            <ButtonDropdown direction="right" toggle={() => { set1Open(!dropdownOpen1) }}
                isOpen={dropdownOpen1}>
                <DropdownToggle className="bg-light text-dark" >
                    {SelectedOperation1}
                </DropdownToggle>
                <DropdownMenu>
                    {/* <DropdownItem onClick={Select} value="0">Owner</DropdownItem> */}
                    <DropdownItem onClick={Select} value="1">Team Leader</DropdownItem>
                    <DropdownItem onClick={Select} value="2">Doctor</DropdownItem>
                    <DropdownItem onClick={Select} value="3">Administrator</DropdownItem>
                </DropdownMenu>
            </ButtonDropdown>
            <span className="input-group-text" id="basic-addon1">-</span>
            <input type="text" className="form-control" placeholder="Username" maxLength="10" name="N" aria-label="Username" aria-describedby="NameHelpInline" onChange={HandleChange} />
            <input type="text" className="form-control" placeholder="Mobile" maxLength="15" name="M" aria-label="Mobile" aria-describedby="MobileHelpInline" onChange={HandleChange} />

        </div>

        <div className="form-floating mb-3">
            <input type="text" className="form-control" id="Password" name="P" key="2" maxLength="16" onChange={HandleChange} />
            <label htmlFor="Password">Password</label>
        </div>
        <label>{Message}{props.Message}</label>
        <div className="mb-3"><button type="button" onClick={() => { Check(); }} className="btn btn-secondary" >Create</button></div>

    </div>
}
export class Users extends Component {
    constructor(props) {
        super(props);
        this.App = props.App;
        this.state = {
            Message :"",
            New: false,
            Users: undefined
        }
    }
    componentDidMount() {
        if (!this.state.Users)
            this.App.SWNWPost({}, "2m33a3eke6pfbrewzkurjaxpbq0gkgaf",
                (resJson) => {                   
                    this.setState({ "Users": resJson.body });
                }
                , (resJson) => {
                  
                });

    }
    
    ChangePassword = e =>{ 
        this.App.SWNWPost({"N":this.state.Users[e.target.getAttribute("data-ix")].N,"NP":true}, "fimu2mvsn5go63ye4aazotcgoa0kgmyt",
        (resJson) => {
            setTimeout(() => {
                window.location.reload();
              }, 200);   
           
        }
        , (resJson) => {
          
        });
        
        
    }
    LockUser = e =>{ 
        this.App.SWNWPost({"N":this.state.Users[e.target.getAttribute("data-ix")].N,"LCK":true}, "fimu2mvsn5go63ye4aazotcgoa0kgmyt",
        (resJson) => {
            setTimeout(() => {
                window.location.reload();
              }, 200);   
           
        }
        , (resJson) => {
          
        });
        
        
    }
    UnlockUser = e =>{ 
        this.App.SWNWPost({"N":this.state.Users[e.target.getAttribute("data-ix")].N,"ULCK":true}, "fimu2mvsn5go63ye4aazotcgoa0kgmyt",
        (resJson) => {
            setTimeout(() => {
                window.location.reload();
              }, 200);   
           
        }
        , (resJson) => {
          
        });        
        
    }
    LogUserOut = e =>{ 
        this.App.SWNWPost({"N":this.state.Users[e.target.getAttribute("data-ix")].N,"LGO":true }, "fimu2mvsn5go63ye4aazotcgoa0kgmyt",
        (resJson) => {
            setTimeout(() => {
                window.location.reload();
              }, 200);   
           
        }
        , (resJson) => {
          
        });        
        
    }
    render() {       
        return <div>
            <div className="d-flex flex-wrap align-items-lg-center justify-content-lg-center">
                {this.state.Users ?
                    this.state.Users.map((u,k) =>
                        <div key={u.U} className="card text-center m-2">
                            <div className="card-header">
                                {u.O === 0 ? "Owner" : u.O === 1 ? "Team Leader" : u.O === 2 ? "Doctor" : u.O === 3 ? "Administrator" : "Unknown"}
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">{u.N}</h5>
                                <div className="d-flex">
                                    <label>Mobile</label><label className="badge bg-dark text-white m-2">{u.M}</label>
                                </div>
                                <div className="d-flex">
                                    <label> Open Connections</label> <label  className="badge bg-dark text-white m-2">{u.D.length} </label>
                                    <button type="button" className="btn btn-outline-danger btn-sm m-1" data-ix={k} onClick={this.LogUserOut} >Log User out</button>
                                </div>
                                <div className="d-flex">
                                    <label>Is Locked</label><label className="badge bg-dark text-white m-2">{u.L ? "Yes" : "No"}</label>
                                    {u.L ?  <button type="button" className="btn btn-outline-success btn-sm m-1" data-ix={k} onClick={this.UnlockUser} >Unlock</button>:<button type="button" className="btn btn-outline-danger btn-sm m-1" data-ix={k} onClick={this.LockUser}>Lock</button> }
                                </div>
                                <button type="button" className="btn btn-outline-danger btn-sm m-1" data-ix={k} onClick={this.ChangePassword} >Reset Password</button>
                              
                            </div>
                            <div className="card-footer text-muted">
                                since {parseInt(Math.abs(new Date().getTime() - new Date(u.CD).getTime()) / (1000 * 3600 * 24))} days
                            </div>
                        </div>

                    )





                    : ""}
                {/* {
    "LL": "2022-10-26T05:36:09.418Z",    
    "D": [
        {
            "U": "5fd0c19f-72f6-453d-9d45-bfeefa21e0ed",
            "LA": "2022-10-26T05:36:31.489Z",
            "O": 0
        },
        {
            "U": "5fd0c19f-72f6-453d-9d45-bfeefa21e0ed",
            "LA": "2022-10-26T05:42:34.311Z",
            "O": 0
        }
    ],
    
    
} */}



                <div className="card text-center m-2" >
                    <div className="card-header">
                        New 
                    </div>
                    <div className="card-body">
                        {this.state.New ?
                            <NewUser Message={this.state.Message} OnSave={(data) => {                                 
                                this.App.SWNWPost(data, "ftchhmitzslgwablabrfwz65nq0qrbbn",
                                (resJson) => {
                                    this.setState({ "New": false });
                                    setTimeout(() => {
                                        window.location.reload();
                                      }, 200);   
                                }
                                , (resJson) => {
                                    this.setState({"Message":resJson.message})
                                });
                                 }} />

                            : <div >
                                <button className="btn btn-outline-info" onClick={() => {this.setState({"Message":""}); this.setState({ "New": true }); }}>+</button>
                            </div>}

                    </div>
                    <div className="card-footer text-muted">
                        
                    </div>
                </div>
            </div>
        </div>
    }
}