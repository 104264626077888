import { Component } from "react";
import { Link } from "react-router-dom";

export class PatientSearch extends Component {
    constructor(props) {
        super(props);
        this.App = props.App;
        this.Parent = props.Parent;
        this.state = {
            First: "",
            Last: "",
            Date: new Date(),
            Key: { F: "" },
            found: localStorage.getItem("found") ? JSON.parse(localStorage.getItem("found")) : undefined,
            founddata: localStorage.getItem("founddata") ? JSON.parse(localStorage.getItem("founddata")) : {},
            max: 10,
            npages: 0,
            cpage: 1,
            cntrpages: [1, 2, 3, 4, 5],
            slice: [],
            Toggle: 0,
            HasOpenSessions:localStorage.getItem("SessionObject") ?true:false
        }
    }


    ToggleButton = () => {

        this.setState({ "Key": { "F": this.state.First } });
        this.setState({ "Key": { "L": this.state.Last } });
        this.setState({ "Key": { "D": this.state.Date } });

    }
    Clear = () => {
        localStorage.removeItem("found");
        this.setState({ "found": undefined, "founddata": {}, "First": "", "Last": "", "Toggle": 0, "Key": { "F": "" }, "max": 10, "npages": 0, "cpage": 1, "cntrpages": [1, 2, 3, 4, 5], "slice": [] });
    }
    GetPatients = (Pget) => {
        this.App.SWNWPost({ Ps: Pget },
            "7g67dffqedrcsz7pqxum7ro7gi0wcvyp",
            (data) => {
                if (Object.keys(data.body).length > 0) {
                    Object.assign(this.state.founddata, data.body);

                    localStorage.setItem("founddata", JSON.stringify(this.state.founddata));
                    this.setState({ "founddata": this.state.founddata });
                }
                else {
                    //Nothing Found;
                }
            },
            (Data) => {

            }, true);
    }
    FindPatient = () => {    
        this.App.SWNWPost(this.state.Key,
            "a6mwpp4rgikrhytv2fxc42u5li0dhgle",
            (data) => {
                if (data.body.length > 0) {
                    localStorage.setItem("found", JSON.stringify(data.body));
                    this.state.found = data.body;
                    this.setState({ "found": data.body });
                    this.PreparePages();
                    this.HanddlePage(1);
                }
                else {
                    this.App.addSysMessage("0 Found", "yellow", "black");
                }
            },
            (Data) => {
                this.App.addSysMessage(Data.message, "Yellow", "black");
            });

    }
    componentDidUpdate() { }
    PreparePages = () => {
        this.state.npages = Math.floor(this.state.found.length / this.state.max) + 1;
        this.setState({ "npages": Math.floor(this.state.found.length / this.state.max) + 1 });

        if (this.state.cpage > this.state.npages) {
            const X = this.state.cntrpages.map((a, k) => { return this.state.npages + 1 - (this.state.cntrpages.length - k) });
            this.HanddlePage(this.state.npages)
            this.setState({ "cntrpages": X });
        }
        this.HanddlePage(this.state.cpage);

    }
    First = () => {
        const X = this.state.cntrpages.map((a, k) => { return k + 1 });
        this.HanddlePage(1);
        this.setState({ "cntrpages": X });

    }
    prev = () => {
        const X = this.state.cntrpages.map((a) => { return a - 1 });
        this.HanddlePage(this.state.cpage - 1);
        this.setState({ "cntrpages": X });
    }
    Next = () => {
        const X = this.state.cntrpages.map((a) => { return a + 1 });
        this.HanddlePage(this.state.cpage + 1);
        this.setState({ "cntrpages": X });
    }
    Last = () => {
        const X = this.state.cntrpages.map((a, k) => { return this.state.npages + 1 - (this.state.cntrpages.length - k) });
        this.HanddlePage(this.state.npages);

        this.setState({ "cntrpages": X });
    }
    HanddlePage = (ix) => {
        this.setState({ "cpage": ix });
        const Xslice = this.state.found.slice((ix * this.state.max) - this.state.max, (ix * this.state.max));
        const Pget = [];
        for (let index = 0; index < Xslice.length; index++) {
            const element = Xslice[index];
            if (!Object.keys(this.state.founddata).includes(element)) {
                Pget.push(element);
            }
        }
        this.GetPatients(Pget);
        this.setState({ "slice": Xslice });
    }

    componentDidMount() {
        if (this.state.found && this.state.found.length > 0) {
            this.PreparePages();
            this.HanddlePage(1);
        }

    }
    render() {
        return <div className="container">
            <h1>Search</h1>
            {!this.state.found ?
                <div className="card text-center m-2">
                    <div className="card-header">
                        Find Patient
                    </div>
                    <div className="card-body">
                        <div className="mb-3">
                            <div className="btn-group" role="group" aria-label="Basic example">
                                <button type="button" className={this.state.Toggle === 0 ? "btn btn-primary" : "btn btn-outline-primary"} onClick={() => { this.setState({ "Toggle": 0, "Key": { "F": this.state.First } }); }}>By First</button>
                                <button type="button" className={this.state.Toggle === 1 ? "btn btn-primary" : "btn btn-outline-primary"} onClick={() => { this.setState({ "Toggle": 1, "Key": { "L": this.state.Last } }); }}>By Last</button>
                                <button type="button" className={this.state.Toggle === 2 ? "btn btn-primary" : "btn btn-outline-primary"} onClick={() => { this.setState({ "Toggle": 2, "Key": { "D": this.state.Date } }); }}>By Date</button>
                            </div>
                        </div>
                        {this.state.Toggle === 0 && <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="nptFirst" key="0" onChange={(e) => { this.state.First = e.target.value; this.setState({ "Key": { "F": this.state.First }, "First": this.state.First }) }} value={this.state.First}  onKeyDown={(e) => { if (e.key === "Enter") { this.FindPatient(); } }}/>
                            <label htmlFor="nptFirst">First Name</label>
                        </div>
                        }
                        {this.state.Toggle === 1 && <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="nptLast" key="1" onChange={(e) => { this.state.Last = e.target.value; this.setState({ "Key": { "L": this.state.Last }, "Last": this.state.Last }) }} value={this.state.Last} onKeyDown={(e) => { if (e.key === "Enter") { this.FindPatient(); } }} />
                            <label htmlFor="nptLast">Last Name</label>
                        </div>
                        }
                        {this.state.Toggle === 2 && <div className="form-floating mb-3">
                            <input type="Date" className="form-control" id="nptDate" key="2" onChange={(e) => {
                                if (e.target.valueAsDate === null || e.target.valueAsDate === undefined) {
                                    return;
                                }
                                this.state.Date = e.target.valueAsDate;
                                this.setState({ "Key": { "D": this.state.Date }, "Date": this.state.Date });
                            }} value={this.state.Date.toISOString().substring(0, 10)}  onKeyDown={(e) => { if (e.key === "Enter") { this.FindPatient(); } }}/>
                            <label htmlFor="nptDate">Date</label>
                        </div>
                        }
                    </div>
                    <div className="card-footer text-muted">
                        <button type="button" className="btn btn-outline-info" onClick={this.FindPatient}>Find</button>
                    </div>
                </div>
                :
                <div className="card text-center m-2">
                    <div className="card-header">
                        Found {this.state.found.length} Patients
                    </div>
                    <div className="card-body">
                        {this.state.slice.map((a, k) => <div key={k}>{Object.keys(this.state.founddata).includes(a) ? <div key={a} className="border border-success m-1 p-1 rounded text-start">{this.state.founddata[a].First && this.state.founddata[a].First} {this.state.founddata[a].Last && this.state.founddata[a].Last} : {this.state.founddata[a].Contact && this.state.founddata[a].Contact.map(c => <span key={c} className="m-1">  {c}</span>)}
                            {this.state.founddata[a].Contact && !this.state.HasOpenSessions && <Link className="btn btn-outline-info btn-sm m-1" data-ix={k} to={'/Profiles/' + this.state.founddata[a].Contact[0]}>Profile</Link>}{this.state.founddata[a].Contact && <Link className="btn btn-outline-info btn-sm m-1" data-ix={k} to={'/Payments/' + this.state.founddata[a].Contact[0]}>Payments</Link>}
                        </div> : <div key={a} className="border border-success m-1 p-1 rounded"><div className="spinner-grow text-success" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div></div>}</div>)}

                        {this.state.found.length < this.state.max ?
                            ""
                            :
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className="page-item">
                                        <button className="page-link" disabled={this.state.cntrpages[0] === 1} aria-label="First" onClick={this.First}><span aria-hidden="true">1 &laquo; </span></button>
                                    </li>
                                    <li className="page-item">
                                        <button className="page-link" disabled={this.state.cntrpages[0] === 1} aria-label="Previous" onClick={this.prev}><span aria-hidden="true">&lsaquo;</span></button>
                                    </li>
                                    {this.state.cntrpages.map(a =>
                                        <li className="page-item" key={a} ><button className={this.state.cpage === a ? "page-link bg-dark text-white" : "page-link"} aria-label={a} data-ix={a} onClick={() => { this.HanddlePage(a) }}><span aria-hidden="true">{a}</span></button></li>
                                    )}
                                    <li className="page-item">
                                        <button className="page-link" aria-label="Next" disabled={this.state.cntrpages[4] === this.state.npages} onClick={this.Next}><span aria-hidden="true" >&rsaquo;</span></button>
                                    </li>
                                    <li className="page-item">
                                        <button className="page-link" aria-label="Last" disabled={this.state.cntrpages[4] === this.state.npages} onClick={this.Last}><span aria-hidden="true">&raquo; {this.state.npages}</span></button>
                                    </li>
                                </ul>
                            </nav>
                        }
                    </div>
                    <div className="card-footer text-muted">
                        Page :   {this.state.cpage}
                        <div className="mb-3">
                            <div className="btn-group" role="group" aria-label="Basic example">
                                <button type="button" className={this.state.max === 10 ? "btn btn-primary" : "btn btn-outline-primary"} onClick={() => {
                                     this.state.max = 10; this.setState({ "Max": 10 }); this.PreparePages()
                                      }}>10/Page</button>
                                <button type="button" className={this.state.max === 25 ? "btn btn-primary" : "btn btn-outline-primary"} onClick={() => { this.state.max = 25; this.setState({ "Max": 25 }); this.PreparePages() }}>25/Page</button>
                                <button type="button" className={this.state.max === 50 ? "btn btn-primary" : "btn btn-outline-primary"} onClick={() => { this.state.max = 50; this.setState({ "Max": 50 }); this.PreparePages() }}>50/Page</button>
                            </div>
                        </div>
                        <button type="button" className="btn btn-outline-info" onClick={this.Clear}>Close this search</button>
                    </div>
                </div>
            }
        </div>
    }
};
export default PatientSearch