import { useState } from "react";
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

export function PPWDIAGv2(props) {
    const Session = props.Session;
    const Target = props.Target;
    if (!Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData[Target]) {
        Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData[Target] = {};
    }
    const [Obj, setObj] = useState(Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData[Target]);
    const [AnotherTitle, setAnotherTitle] = useState("");
    const [IsOpen, setIsOpen] = useState(false);
    const [IsOpen2, setIsOpen2] = useState(false);
    const [ShowOthers, setShowOthers] = useState(true);
    const Droptxt = "Select";
    const [SelectedOperation, setSelectedOperation] = useState(Droptxt);
    const [SelectedOperation2, setSelectedOperation2] = useState(Droptxt);

    const Select = (e) => {
        setShowOthers(false);
        setSelectedOperation(e.target.innerText);
        Obj[e.target.innerText] = Obj[e.target.innerText] ? Obj[e.target.innerText] : { P: "", V: "" };
        Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData[Target][e.target.innerText] = Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData[Target][e.target.innerText] ? Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData[Target][e.target.innerText] : { P: "", V: "" };
        setObj(Obj);
        Session.setState({ "SessionObject": Session.state.SessionObject });
        Session.StorageSave();
    }
    const SelectOthers = (e) => {
        setShowOthers(true);

    }
    const Select2 = (e) => {
        if (ShowOthers) {
            Obj[AnotherTitle].P = e.target.innerText;
            Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData[Target][AnotherTitle].P = e.target.innerText;
            setObj(Obj);
            Session.setState({ "SessionObject": Session.state.SessionObject });
            Session.StorageSave();
        }
        else {
            setSelectedOperation2(e.target.innerText);
            Obj[SelectedOperation].P = e.target.innerText;
            Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData[Target][SelectedOperation].P = e.target.innerText;
            setObj(Obj);
            Session.setState({ "SessionObject": Session.state.SessionObject });
            Session.StorageSave();
        }

    }
    const handleChange = e => {
        Obj[e.target.name].V = e.target.value;
        setObj(Obj);
        Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData[Target][e.target.name].V = e.target.value;
        Session.setState({ "SessionObject": Session.state.SessionObject });
        Session.StorageSave();
    }
    return <div className="form-control">
        <div className="d-flex flex-wrap align-items-lg-center justify-content-lg-start">
            {/* <ButtonDropdown direction="right" toggle={() => { setIsOpen(!IsOpen) }}
                isOpen={IsOpen} >
                <DropdownToggle className="bg-light text-dark" caret>
                    {SelectedOperation}
                </DropdownToggle>
                <DropdownMenu style={{ height: "200px", overflowY: "scroll" }}>
                    <DropdownItem onClick={Select}>Cervical</DropdownItem>
                    <DropdownItem onClick={Select}>C0</DropdownItem>
                    <DropdownItem onClick={Select}>C1</DropdownItem>
                    <DropdownItem onClick={Select}>C2</DropdownItem>
                    <DropdownItem onClick={Select}>C3</DropdownItem>
                    <DropdownItem onClick={Select}>C4</DropdownItem>
                    <DropdownItem onClick={Select}>C5</DropdownItem>
                    <DropdownItem onClick={Select}>C6</DropdownItem>
                    <DropdownItem onClick={Select}>C7</DropdownItem>
                    <DropdownItem onClick={Select}>C/T</DropdownItem>
                    <DropdownItem onClick={Select}>Dorsal</DropdownItem>
                    <DropdownItem onClick={Select}>T1</DropdownItem>
                    <DropdownItem onClick={Select}>T2</DropdownItem>
                    <DropdownItem onClick={Select}>T3</DropdownItem>
                    <DropdownItem onClick={Select}>T4</DropdownItem>
                    <DropdownItem onClick={Select}>T5</DropdownItem>
                    <DropdownItem onClick={Select}>T6</DropdownItem>
                    <DropdownItem onClick={Select}>T7</DropdownItem>
                    <DropdownItem onClick={Select}>T8</DropdownItem>
                    <DropdownItem onClick={Select}>T9</DropdownItem>
                    <DropdownItem onClick={Select}>T10</DropdownItem>
                    <DropdownItem onClick={Select}>T11</DropdownItem>
                    <DropdownItem onClick={Select}>T12</DropdownItem>
                    <DropdownItem onClick={Select}>Ribs</DropdownItem>
                    <DropdownItem onClick={Select}>T1</DropdownItem>
                    <DropdownItem onClick={Select}>T2</DropdownItem>
                    <DropdownItem onClick={Select}>T3</DropdownItem>
                    <DropdownItem onClick={Select}>T4</DropdownItem>
                    <DropdownItem onClick={Select}>T5</DropdownItem>
                    <DropdownItem onClick={Select}>T6</DropdownItem>
                    <DropdownItem onClick={Select}>T7</DropdownItem>
                    <DropdownItem onClick={Select}>T8</DropdownItem>
                    <DropdownItem onClick={Select}>T9</DropdownItem>
                    <DropdownItem onClick={Select}>T10</DropdownItem>
                    <DropdownItem onClick={Select}>T11</DropdownItem>
                    <DropdownItem onClick={Select}>T12</DropdownItem>
                    <DropdownItem onClick={Select}>Lumbar</DropdownItem>
                    <DropdownItem onClick={Select}>L1</DropdownItem>
                    <DropdownItem onClick={Select}>L2</DropdownItem>
                    <DropdownItem onClick={Select}>L3</DropdownItem>
                    <DropdownItem onClick={Select}>L4</DropdownItem>
                    <DropdownItem onClick={Select}>L5</DropdownItem>
                    <DropdownItem onClick={Select}>Sacral</DropdownItem>
                    <DropdownItem onClick={Select}>S1</DropdownItem>
                    <DropdownItem onClick={Select}>S2</DropdownItem>
                    <DropdownItem onClick={Select}>S3</DropdownItem>
                    <DropdownItem onClick={Select}>S4</DropdownItem>
                    <DropdownItem onClick={Select}>S5</DropdownItem>
                    <DropdownItem onClick={Select}>Abdomen</DropdownItem>
                    <DropdownItem onClick={Select}>TMJ</DropdownItem>
                    <DropdownItem onClick={Select}>SCJ</DropdownItem>
                    <DropdownItem onClick={Select}>ACJ</DropdownItem>
                    <DropdownItem onClick={Select}>GHJ</DropdownItem>
                    <DropdownItem onClick={Select}>Elbow</DropdownItem>
                    <DropdownItem onClick={Select}>Wrist</DropdownItem>
                    <DropdownItem onClick={Select}>SIJ</DropdownItem>
                    <DropdownItem onClick={Select}>Hip</DropdownItem>
                    <DropdownItem onClick={Select}>Knee</DropdownItem>
                    <DropdownItem onClick={Select}>Ankle</DropdownItem>
                    <DropdownItem onClick={Select}>Foot</DropdownItem>
                    <DropdownItem onClick={SelectOthers}>Others</DropdownItem>
                </DropdownMenu>
            </ButtonDropdown> */}
            <div className={ShowOthers ? "d-flex flex-wrap align-items-lg-center justify-content-lg-start " : "d-flex flex-wrap align-items-lg-center justify-content-lg-start visually-hidden"}>
                <label className="m-1"> Target </label>
                <input type="text" style={{ width: "150px" }} className="form-control m-1" value={AnotherTitle} onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        if (AnotherTitle === "") {
                            Session.App.addSysMessage("Please add Other", "darkblue", "white");
                            return;
                        }
                        Obj[AnotherTitle] = Obj[AnotherTitle] ? Obj[AnotherTitle] : { P: "", V: "" };
                        Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData[Target][AnotherTitle] = Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData[Target][AnotherTitle] ? Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData[Target][AnotherTitle] : { P: "", V: "" };
                        setObj(Obj);
                        Session.setState({ "SessionObject": Session.state.SessionObject });
                        Session.StorageSave();
                    }
                }} onChange={(e) => { setAnotherTitle(e.target.value) }} /><button type='button' className='m-1 btn btn-outline-success ' onClick={() => {
                    if (AnotherTitle === "") {
                        Session.App.addSysMessage("Please add Other", "darkblue", "white");
                        return;
                    }
                    Obj[AnotherTitle] = Obj[AnotherTitle] ? Obj[AnotherTitle] : { P: "", V: "" };
                    Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData[Target][AnotherTitle] = Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData[Target][AnotherTitle] ? Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData[Target][AnotherTitle] : { P: "", V: "" };
                    setObj(Obj);
                    Session.setState({ "SessionObject": Session.state.SessionObject });
                    Session.StorageSave();
                }
                } >+</button>
            </div>
            {/* <ButtonDropdown className="m-1" direction="right" toggle={() => { setIsOpen2(!IsOpen2) }}
                isOpen={IsOpen2} disabled={SelectedOperation === Droptxt && AnotherTitle === ""}>
                <DropdownToggle className="bg-light text-dark" caret>
                    {SelectedOperation2}
                </DropdownToggle>
                <DropdownMenu >
                    <DropdownItem onClick={Select2}>L</DropdownItem>
                    <DropdownItem onClick={Select2}>R</DropdownItem>
                    <DropdownItem onClick={Select2}>B</DropdownItem>
                </DropdownMenu>
            </ButtonDropdown> */}



        </div>

        {Object.keys(Obj).map(a => <div key={a} className="form-group m-1">
            <div className="d-flex align-items-center justify-content-between">
                <label htmlFor={a}>{a}⇢
                    <div className="btn-group" role="group" aria-label="Basic example">
                        <button type="button" className={Obj[a].P === "L" ? "btn btn-primary btn-sm" : "btn btn-outline-primary btn-sm"} onClick={() => {                              
                              Obj[a].P = "L";
                              Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData[Target][a].P = "L";
                              setObj(Obj);
                              Session.setState({ "SessionObject": Session.state.SessionObject });
                              Session.StorageSave();
                        }}>L</button>
                        <button type="button" className={Obj[a].P === "B" ? "btn btn-primary btn-sm" : "btn btn-outline-primary btn-sm"} onClick={() => { Obj[a].P = "B";
                              Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData[Target][a].P = "B";
                              setObj(Obj);
                              Session.setState({ "SessionObject": Session.state.SessionObject });
                              Session.StorageSave();  }}>B</button>
                        <button type="button" className={Obj[a].P === "R" ? "btn btn-primary btn-sm" : "btn btn-outline-primary btn-sm"} onClick={() => { Obj[a].P = "R";
                              Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData[Target][a].P = "R";
                              setObj(Obj);
                              Session.setState({ "SessionObject": Session.state.SessionObject });
                              Session.StorageSave(); }}>R</button>
                    </div>
                    </label><button type='button' className='m-1 btn btn-outline-danger btn-sm' onClick={() => {
                        delete Obj[a];
                        delete Session.state.SessionObject.Sessions[Session.state.SessionObject.ID].SessionData[Target][a];
                        setObj(Obj);
                        Session.setState({ "SessionObject": Session.state.SessionObject });
                        Session.StorageSave();
                    }
                    } >X</button>
            </div>
            <input type="text" className="form-control" name={a} value={Obj[a].V} onChange={handleChange} />

        </div>)}
        <div className="row"></div>
    </div>

}
export default PPWDIAGv2