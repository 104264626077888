import { Component, React, useEffect, useState } from 'react'
import { LabArray } from './labarray'
class LabSearch extends Component
{
    Laboratory = undefined;
    constructor(props)
    {
        super(props);
        this.Laboratory = props.Lab
        this.state={
            list:[]
        }
    }
    render(){
        return <div><h3>Search</h3>
        <input type="text" onChange={(e) => {
            if (e.target.value.length > 0) {
                this.state.list =[]
            
                LabArray.forEach((K) => {
                    if (K.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase())) {
                        if(!this.state.list.includes(K))
                            this.state.list.push(K);
                    }

                })
                this.setState({"list":this.state.list});
            }

        }}
        />
        {this.state.list.map(a => <div key={a} className='mb-1 p-1 rounded border border-info'>{a}<input type="text" onChange={(e) => { this.Laboratory.Add({ k: a, v: e.target.value }) }} /><hr></hr></div>)}
        </div>
    }
}

export class Lab extends Component {
    Session = undefined;
    Target = undefined;
    constructor(props) {
        super(props)
        this.Session = props.Session;
        this.Target = props.Target
        //props.Session.state.SessionObject.Sessions[props.Session.state.SessionObject.ID]
        this.state = {
            FinalList:  this.Session.state.SessionObject.Sessions[this.Session.state.SessionObject.ID].SessionData[this.Target]
        }
    }
    Add = (obj) => {
        this.state.FinalList[obj.k] = obj.v;
        this.setState({ "FinalList": this.state.FinalList });
        this.Session.state.SessionObject.Sessions[this.Session.state.SessionObject.ID].SessionData[this.Target] = this.state.FinalList;
        this.Session.StorageSave();
    }
    render() {
        return <div>{Object.keys(this.state.FinalList).map((K) => <div key={K} className='mb-1 p-1 rounded border border-success'>{K}:<span> {this.state.FinalList[K]}</span></div>)}

            <LabSearch Lab={this} />
        </div>
    }
}

export default Lab