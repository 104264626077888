import { Component } from "react";

export class FNPatient extends Component {    
    constructor(props) {
        super(props);
        this.App = props.App;
        this.Parent = props.Parent;
        this.state = {
            IsNew: false,
            ContactToFind: "",
            First: "",
            Last: ""
        }
    }

    NewPatient=()=> {   
        if(this.state.First === ""  )
        {
            this.App.addSysMessage("First Name is required ", "darkblue", "white");  
            return;
        }   
        if(this.state.Last === ""  )
        {
            this.App.addSysMessage("Last Name is required", "darkblue", "white");  
            return;
        }  
        const re = /^[[0-9]+$/;
        if (!re.test(this.state.ContactToFind)) {
            this.App.addSysMessage("Mobile : only Numbers", "darkblue", "white");              
            return;
        }   
        let Pdata = { F: this.state.First, L: this.state.Last, C: [this.state.ContactToFind], DC: 0 };        
        this.App.SWNWPost(Pdata, "rb4klfrtuc6skqtoep4ko4buxi0azkhr", (data) => {
            this.Parent.setState({ "SessionObject":{PID:data.body.ID, Patient:data.body,SessionsDates:[]} });
                     
            this.setState({"First":"","Last":"", "IsNew": false, "ContactToFind": "" });
        }, () => { });
    }
    FindPatient =()=> {
        const re = /^[[0-9]+$/;
        if (!re.test(this.state.ContactToFind)) {
            this.App.addSysMessage("Mobile : only Numbers", "darkblue", "white");              
            return;
        }   
        this.App.SWNWPost({ C: this.state.ContactToFind },
             "tyrnzpxfhn55cad575b4gyquha0gkvxd",
              (data) => {                
                this.Parent.setState({ "SessionObject":{ PID:data.body.ID, Patient:data.body.Patient,SessionsDates:data.body.SessionsDates} });            
                  this.setState({ "IsNew": false, "ContactToFind": "" });
        },
        () => {
            this.setState({ "IsNew": true });
        });

    }
    render() {
        return <div className="d-lg-flex align-items-lg-center justify-content-lg-center">

            {this.state.IsNew ?
                <div className="card text-center m-2">
                    <div className="card-header">
                        New Patient
                    </div>
                    <div className="card-body">
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="nptFirst" key="2" onChange={(e) => { this.state.First = e.target.value; this.setState({ "First": this.state.First }) }} value={this.state.First}     onKeyDown={(e)=>{
        if(e.key === "Enter")
        {
            this.NewPatient();
        }
      }}/>
                            <label htmlFor="nptFirst">First Name</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="nptLast" key="3" onChange={(e) => { this.state.Last = e.target.value; this.setState({ "Last": this.state.Last }) }} value={this.state.Last}     onKeyDown={(e)=>{
        if(e.key === "Enter")
        {
            this.NewPatient();
        }
      }}/>
                            <label htmlFor="nptLast">Last Name</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="nptContact" key="1" onChange={(e) => { this.state.ContactToFind = e.target.value; this.setState({ "ContactToFind": this.state.ContactToFind }) }} value={this.state.ContactToFind}     onKeyDown={(e)=>{
        if(e.key === "Enter")
        {
          this.NewPatient();
        }
      }}/>
                            <label htmlFor='nptContact'>Contact Number</label>
                        </div>
                    </div>
                    <div className="card-footer text-muted">
                        <button type="button" className="btn btn-outline-info" onClick={this.NewPatient}>New </button>
                    </div>
                </div>
                :
                <div className="card text-center m-2">
                    <div className="card-header">
                        Find Patient
                    </div>
                    <div className="card-body">
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="nptContact" key="1" onChange={(e) => { this.state.ContactToFind = e.target.value; this.setState({ "ContactToFind": this.state.ContactToFind }) }} value={this.state.ContactToFind} onKeyDown={(e)=>{
        if(e.key === "Enter")
        {
            this.FindPatient();
        }
      }}/>
                            <label htmlFor='nptContact'>Contact Number</label>
                        </div>
                    </div>
                    <div className="card-footer text-muted">
                        <button type="button" className="btn btn-outline-info" onClick={this.FindPatient}>Find</button>
                    </div>
                </div>
            }
        </div>
    }
};
export default FNPatient