import { Component } from "react";
import { Collapse } from "reactstrap";
import FNPatient from "./NewPatient";
import PatientInfo from "./PatientInfo";
import SWNWField, { SWNWFields } from "./SessionModel";
import logo from '../../img/Logo.png'

export class PatientProfile extends Component {
    App = undefined;
    constructor(props) {
        super(props);
        this.App = props.App;
        this.state = {
            SessionObject: localStorage.getItem("SessionObject") ? JSON.parse(localStorage.getItem("SessionObject")) : undefined,
            IsOpen: false,
            ShowOld:false,
            URLParam:undefined
        }
    }
    StorageSave() {
        localStorage.setItem("SessionObject", JSON.stringify(this.state.SessionObject));
    }
    HandleOptions = (e) => {
        this.state.SessionObject.Sessions[this.state.SessionObject.ID].SessionData[e.target.name] = parseInt(e.target.getAttribute("data-ix"));
        this.setState({ "SessionObject": this.state.SessionObject });
        this.StorageSave();
    }
    HandleText = (e) => {
        this.state.SessionObject.Sessions[this.state.SessionObject.ID].SessionData[e.target.name] = e.target.value;
        this.setState({ "SessionObject": this.state.SessionObject });
        this.StorageSave();
    }
    HandleNumber = (e) => {
        this.state.SessionObject.Sessions[this.state.SessionObject.ID].SessionData[e.target.name] = parseInt(e.target.value);
        this.setState({ "SessionObject": this.state.SessionObject });
        this.StorageSave();
    }
    SaveSession = () => {

        const DataToSend = {
            ID: this.state.SessionObject.ID,
            PID: this.state.SessionObject.Patient.ID,
            D: this.state.SessionObject.Sessions[this.state.SessionObject.ID].Date,
            PD: {
                ID: this.state.SessionObject.Patient.ID,
                First: this.state.SessionObject.Patient.First,
                Last: this.state.SessionObject.Patient.Last,
                Date: this.state.SessionObject.Patient.Date,
                DOB: this.state.SessionObject.Patient.DOB,
                Email: this.state.SessionObject.Patient.Email,
                Address: this.state.SessionObject.Patient.Address,
                Contact: this.state.SessionObject.Patient.Contact,
                Discount: this.state.SessionObject.Patient.Discount,
                Referer:this.state.SessionObject.Patient.Referer,
                Country:this.state.SessionObject.Patient.Country,
                Hand:this.state.SessionObject.Patient.Hand
            },
            SD: this.state.SessionObject.Sessions[this.state.SessionObject.ID].SessionData
        }

        this.App.SWNWPost(DataToSend, "w4ifnh6kmsocullvoaodbpuoru0ctxbq", (data) => {
            this.App.addSysMessage("Session Saved", "darkgreen", "white");
        }, (Data) => {
            //;
            this.App.addSysMessage(Data.message, "gold", "darkred");

        });
    }
    Cancel = () => {
        this.setState({ "SessionObject": undefined });
        localStorage.removeItem("SessionObject");
    }
    FinishSession = () => {
        const DataToSend = {
            ID: this.state.SessionObject.ID,
            PID: this.state.SessionObject.Patient.ID,
            D: this.state.SessionObject.Sessions[this.state.SessionObject.ID].Date,
            PD: {
                ID: this.state.SessionObject.Patient.ID,
                First: this.state.SessionObject.Patient.First,
                Last: this.state.SessionObject.Patient.Last,
                Date: this.state.SessionObject.Patient.Date,
                DOB: this.state.SessionObject.Patient.DOB,
                Email: this.state.SessionObject.Patient.Email,
                Address: this.state.SessionObject.Patient.Address,
                Contact: this.state.SessionObject.Patient.Contact,
                Discount: this.state.SessionObject.Patient.Discount,
                Referer:this.state.SessionObject.Patient.Referer,
                Country:this.state.SessionObject.Patient.Country,
                Hand:this.state.SessionObject.Patient.Hand
            },
            SD: this.state.SessionObject.Sessions[this.state.SessionObject.ID].SessionData,
            F: true
        }
        this.App.SWNWPost(DataToSend, "w4ifnh6kmsocullvoaodbpuoru0ctxbq", (data) => {
            this.App.addSysMessage("Session Finished", "darkgreen", "white");
            this.Cancel();
        }, (Data) => {
            this.App.addSysMessage(Data.message, "gold", "darkred");
        });
    }
    componentDidMount() {
        const arr = window.location.pathname.split('/');
        const URLParam = arr.pop();

        if(URLParam!== "Profiles")
        {
        this.setState({"URLParam":URLParam});
        }
        if(URLParam!== "Profiles" && this.state.SessionObject)
        {
            //this.App.addSysMessage("Sorry you have an open session", "gold", "black");
        }
        if (!this.state.SessionObject) {
            this.App.SWNWPost({}, "erhlylyi6eimfl3fjrepyx6ioa0cojtg", (data) => {
                data.body.Patient.Date = data.body.Sessions[data.body.ID].Date;
                if(!data.body.Sessions[data.body.ID].SessionData["SwNw61"])
                {
                    data.body.Sessions[data.body.ID].SessionData["SwNw61"] = "";
                }
                this.state.FirstSessionDate = new Date();
                for (const SessionId in  data.body.Sessions) {
                    if (Object.hasOwnProperty.call( data.body.Sessions, SessionId)) {
                        if(new Date(this.state.FirstSessionDate) > new Date( data.body.Sessions[SessionId].Date))
                        {
                            this.state.FirstSessionDate =  data.body.Sessions[SessionId].Date
                        }
                    }
                }
                this.setState({"FirstSessionDate":  this.state.FirstSessionDate});  
                
                localStorage.setItem("SessionObject", JSON.stringify(data.body));
                this.setState({ "SessionObject": data.body });
                if (this.state.URLParam){
                    this.App.addSysMessage("Sorry you have an open session : "+data.body.Patient.First+" "+data.body.Patient.Last, "gold", "black");                    
                    setTimeout(() => {                        
                        window.location.replace("/Profiles");    
                    }, 5000);
                    
                }
            }, (Data) => {
                if (this.state.URLParam) {
                    this.App.SWNWPost({ C: this.state.URLParam },
                        "tyrnzpxfhn55cad575b4gyquha0gkvxd",
                        (sdata) => {
                            this.setState({ "SessionObject": { PID: sdata.body.ID, Patient: sdata.body.Patient, SessionsDates: sdata.body.SessionsDates } });
                        },
                        (sdata) => {
                            this.App.addSysMessage(sdata.message, "gold", "black");
                        });

                }

            });
        }
        else if(this.state.SessionObject.Sessions){
            
            this.state.FirstSessionDate = new Date();
            for (const SessionId in this.state.SessionObject.Sessions) {
                if (Object.hasOwnProperty.call(this.state.SessionObject.Sessions, SessionId)) {
                    if(new Date(this.state.FirstSessionDate) > new Date(this.state.SessionObject.Sessions[SessionId].Date))
                    {
                        this.state.FirstSessionDate = this.state.SessionObject.Sessions[SessionId].Date
                    }
                    
                    
                }
            }
            this.setState({"FirstSessionDate":  this.state.FirstSessionDate});  
        }
        
    }

    render() {
        return <div className="p-3" >
            <h1>Profile</h1>
            {!this.state.SessionObject ?
                <FNPatient App={this.App} Parent={this} /> :
                <div >
                    {this.state.SessionObject.Sessions ? <>   
                    
                        <PatientInfo App={this.App} Parent={this} />
                        <div className=" d-lg-flex  justify-content-lg-between">

                            <div className="flex-fill " >

                                
                            </div>
                            <div className="flex-fill">

                               
                            </div>
                            <div className="" style={{ width: "320px" }}>
                                
                            </div>
                        </div>
                        <div >                            
                            <div className="row">
                            <div className="col-lg-6 m-0">
                                <SWNWField Session={this} Field={SWNWFields[0]} ShowOld={this.state.ShowOld} />
                                <SWNWField Session={this} Field={SWNWFields[3]} ShowOld={this.state.ShowOld} />
                            </div>
                            <div className="col-lg-6  m-0">
                                <SWNWField Session={this} Field={SWNWFields[1]} ShowOld={this.state.ShowOld} />
                                <SWNWField Session={this} Field={SWNWFields[2]} ShowOld={this.state.ShowOld} />
                                <div className="col-lg-12 d-lg-flex  justify-content-lg-between">
                                    <SWNWField Session={this} Field={SWNWFields[4]} ShowOld={this.state.ShowOld} />
                                    <SWNWField Session={this} Field={SWNWFields[5]} ShowOld={this.state.ShowOld} />
                                    <SWNWField Session={this} Field={SWNWFields[6]} ShowOld={this.state.ShowOld} />
                                </div>
                            </div>
                            </div>
                        
                            {/* <SWNWField Session={this} Field={SWNWFields[7]} ShowOld={this.state.ShowOld} /> */}
                            <div className=" d-lg-flex  justify-content-lg-between">

                                <div className="flex-fill " >

                                    <SWNWField Session={this} Field={SWNWFields[8]} ShowOld={this.state.ShowOld} />

                                </div>
                                <div className="flex-fill">

                                    <SWNWField Session={this} Field={SWNWFields[9]} ShowOld={this.state.ShowOld} />
                                    <SWNWField Session={this} Field={SWNWFields[10]} ShowOld={this.state.ShowOld} />
                                    <SWNWField Session={this} Field={SWNWFields[11]} ShowOld={this.state.ShowOld} />
                                </div>
                                <div className="" style={{maxWidth: "320px"}}>
                                    <SWNWField Session={this} Field={SWNWFields[12]} ShowOld={this.state.ShowOld} />
                                </div>
                            </div>

                            <div className="d-lg-flex  justify-content-lg-between">
                                <div className="flex-fill">
                                    <div className=" " ><SWNWField Session={this} Field={SWNWFields[13]} ShowOld={this.state.ShowOld} /></div>
                                    <div className=" " ><SWNWField Session={this} Field={SWNWFields[14]} ShowOld={this.state.ShowOld} /></div>
                                    <div className=" " ><SWNWField Session={this} Field={SWNWFields[17]} ShowOld={this.state.ShowOld} /></div>
                                    <div className=" " ><SWNWField Session={this} Field={SWNWFields[15]} ShowOld={this.state.ShowOld} /></div>
                                    <div className=" " ><SWNWField Session={this} Field={SWNWFields[16]} ShowOld={this.state.ShowOld} /></div>

                                </div>
                                <div className=" flex-fill">

                                    <div className=" " ><SWNWField Session={this} Field={SWNWFields[18]} ShowOld={this.state.ShowOld} /></div>
                                    <div className=" " ><SWNWField Session={this} Field={SWNWFields[19]} ShowOld={this.state.ShowOld} /></div>
                                    <div className=" " ><SWNWField Session={this} Field={SWNWFields[20]} ShowOld={this.state.ShowOld} /></div>
                                    <div className=" " ><SWNWField Session={this} Field={SWNWFields[48]} ShowOld={this.state.ShowOld} /></div>
                                    <div className=" " ><SWNWField Session={this} Field={SWNWFields[49]} ShowOld={this.state.ShowOld} /></div>
                                    <div className=" " ><SWNWField Session={this} Field={SWNWFields[50]} ShowOld={this.state.ShowOld} /></div>
                                    <div className=" " ><SWNWField Session={this} Field={SWNWFields[21]} ShowOld={this.state.ShowOld} /></div>
                                    <div className=" " ><SWNWField Session={this} Field={SWNWFields[22]} ShowOld={this.state.ShowOld} /></div>
                                </div>
                                <div className="" style={{maxWidth: "320px"}}>

                                    <SWNWField Session={this} Field={SWNWFields[40]} ShowOld={this.state.ShowOld} />
                                </div>
                            </div>
                            <div className="d-lg-flex   justify-content-lg-between">
                                <div className="flex-fill " >
                                    <SWNWField Session={this} Field={SWNWFields[23]} ShowOld={this.state.ShowOld} />
                                    <SWNWField Session={this} Field={SWNWFields[24]} ShowOld={this.state.ShowOld} />
                                    <SWNWField Session={this} Field={SWNWFields[25]} ShowOld={this.state.ShowOld} />
                                    <SWNWField Session={this} Field={SWNWFields[26]} ShowOld={this.state.ShowOld} />
                                    <SWNWField Session={this} Field={SWNWFields[27]} ShowOld={this.state.ShowOld} />
                                    <SWNWField Session={this} Field={SWNWFields[28]} ShowOld={this.state.ShowOld} />
                                    <SWNWField Session={this} Field={SWNWFields[29]} ShowOld={this.state.ShowOld} />
                                    <SWNWField Session={this} Field={SWNWFields[30]} ShowOld={this.state.ShowOld} />
                                </div>
                                <div className="flex-fill" >
                                    <SWNWField Session={this} Field={SWNWFields[31]} ShowOld={this.state.ShowOld} />
                                    <SWNWField Session={this} Field={SWNWFields[32]} ShowOld={this.state.ShowOld} />
                                    <SWNWField Session={this} Field={SWNWFields[33]} ShowOld={this.state.ShowOld} />
                                    <SWNWField Session={this} Field={SWNWFields[34]} ShowOld={this.state.ShowOld} />
                                    {/* <SWNWField Session={this} Field={SWNWFields[35]} ShowOld={this.state.ShowOld} /> */}
                                    <SWNWField Session={this} Field={SWNWFields[36]} ShowOld={this.state.ShowOld} />
                                    <SWNWField Session={this} Field={SWNWFields[37]} ShowOld={this.state.ShowOld} />
                                    <SWNWField Session={this} Field={SWNWFields[38]} ShowOld={this.state.ShowOld} />
                                    <SWNWField Session={this} Field={SWNWFields[39]} ShowOld={this.state.ShowOld} />
                                </div>
                                <div className="flex-fill">
                                    <SWNWField Session={this} Field={SWNWFields[41]} ShowOld={this.state.ShowOld} />
                                    <SWNWField Session={this} Field={SWNWFields[42]} ShowOld={this.state.ShowOld} />
                                    <SWNWField Session={this} Field={SWNWFields[43]} ShowOld={this.state.ShowOld} />
                                    <SWNWField Session={this} Field={SWNWFields[44]} ShowOld={this.state.ShowOld} />                                    
                                    {new Date(this.state.FirstSessionDate).toDateString() ===  new Date(this.state.SessionObject.Sessions[this.state.SessionObject.ID].Date).toDateString()?"":
                                    <SWNWField Session={this} Field={SWNWFields[51]} ShowOld={this.state.ShowOld} />
                                    }
                                    
                                </div>
                            </div>
                            <div className="d-lg-flex   justify-content-lg-between">
                                <div className="flex-fill " >

                                    {/* <SWNWField Session={this} Field={SWNWFields[45]} ShowOld={this.state.ShowOld} /> */}
                                </div>
                                <div className="flex-fill " >
                                    {/* <SWNWField Session={this} Field={SWNWFields[46]} ShowOld={this.state.ShowOld} />
                            <SWNWField Session={this} Field={SWNWFields[47]} ShowOld={this.state.ShowOld} /> */}
                                </div>

                            </div>
                            <div className="col-lg-2 p-1 rounded shadow" style={{ position: "fixed", left: "0", top: "0", backgroundColor: "white" }}>
                            
                                <h4 className="my-0 fw-normal">Session Control <button type="button" className="btn btn-outline-info" onClick={() => this.setState({ "IsOpen": !this.state.IsOpen })}>{this.state.IsOpen ? "⟰" : "⟱"}</button>               </h4>
                                <Collapse isOpen={this.state.IsOpen}>
                                <button type="button" className="btn btn-outline-info btn-sm m-1" onClick={()=>{
                                    this.setState({"ShowOld":!this.state.ShowOld})
                                }}>{!this.state.ShowOld?"Show":"Hide"}</button>
                                    <button type="button" className="btn btn-outline-info btn-sm m-1" onClick={this.SaveSession}>Save</button>
                                    <button type="button" className="btn btn-outline-info btn-sm m-1" onClick={this.FinishSession}   >Finish</button>
                                </Collapse>



                            </div>
                        </div>
                    </> :
                        <PatientInfo App={this.App} Parent={this} />
                    }

                </div>

            }

        </div>
    }
}
export default PatientProfile